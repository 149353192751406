import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { getFirstRankQuestion, getRemainingLessons } from '../../store/selectors/play.selector';
import { setRankSelection } from '../../store/actions/play.actions';

@Component({
  selector: 'app-intro-side',
  templateUrl: './intro-side.component.html',
  styleUrls: ['./intro-side.component.scss'],
})
export class IntroSideComponent implements OnInit {

  question = this.store$.pipe(select(getFirstRankQuestion));
  lessons = this.store$.pipe(select(getRemainingLessons));

  constructor(private store$: Store<IAppState>) { }

  onSelectionChange(id: number) {
    this.store$.dispatch(setRankSelection({ id }));
  }

  ngOnInit() { }

}
