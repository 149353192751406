import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { take } from 'rxjs/operators';
import { EGameStatus, ESceneStatus, EBonusStatus, ERankStatus, IScenePlayModel, ILessonPlayModel, IBonusPlayModel, IStoryPlayModel } from '../../models';
import { MenuController } from '@ionic/angular';
import { getGame, getSelectedScene } from '../selectors/play.selector';
import { setStatus, setRank1Status, setRank2Status, answerPreQuestion, setSceneViewState, setBonusStatus, resolveBonus, updateAfterGuess, cheatPlay, selectBonus, setSidePaneEnabled, setSidePaneDisabled } from '../actions/play.actions';
import { postStartGameToServer, postEndGameToServer, postGameStatsToServer } from '../actions/security.actions';
import { saveMove, saveBonus, saveExperience } from '../actions/track.actions';
import { saveRankBeginning, saveRankEnd } from '../actions/track.actions';
import { NavService } from './nav.service';
//import { forkJoin } from 'rxjs';
//import { SecurityService } from '../service/security.service';

@Injectable({
    providedIn: 'root',
})
export class GameService {

    constructor(private store$: Store<IAppState>,
        private menu: MenuController,
        private navService: NavService
        //private securityService: SecurityService
    ) { }


    /* PRIVATE FUNCTIONS */

    private advanceGameState(status: EGameStatus) {
        this.store$.dispatch(setStatus({ status }));
    }

    private advanceRank2State(currentStatus: ERankStatus) {
        if (currentStatus === ERankStatus.HIDE) {
            this.store$.dispatch(setRank2Status({ status: ERankStatus.QUESTION }));
        } else if (currentStatus === ERankStatus.QUESTION) {
            this.store$.dispatch(setRank2Status({ status: ERankStatus.RESULT }));
        }
    }

    private advanceSceneViewState(status: ESceneStatus) {
        this.store$.dispatch(setSceneViewState({ status }));
    }

    private getUnsolvedSceneCount(scenes: IScenePlayModel[]) {
        return scenes.filter(scene => !scene.isSolved && !scene.isCutScene).length;
    }

    private getNextBonusIndex(game: IStoryPlayModel): number {
        let index: number = game.selectedBonus;
        let i: number;
        for (i = index + 1; i < game.bonusQuestions.length; i++) {
            if (game.bonusQuestions[i].isVisible) return i;
        }
        for (i = 0; i < index; i++) {
            if (game.bonusQuestions[i].isVisible) return i;
        }
        return index;
    }

    private updateGameStats() {
        this.store$.dispatch(postGameStatsToServer());
    }

    private postGameOver(passed: boolean = true) {
        this.updateGameStats();
        setTimeout(() => {
            this.store$.dispatch(postEndGameToServer({ passed }));
        }, 1000)

        /*
        this.securityService.postGameStatsToAPI().subscribe(result => {
            this.securityService.postEndGameToAPI(passed);
        })
        */

        /*
        forkJoin([
            this.securityService.postGameStatsToAPI()
            ]
        ).subscribe(([res1]) => {
            this.securityService.postEndGameToAPI(passed)
        });
        */

    }

    private updateLesson(gameStatus: EGameStatus, sceneStatus: ESceneStatus, correct: boolean, reward: number, sceneId: number, lessonId: number, attempt: number) {
        this.store$.dispatch(updateAfterGuess({ gameStatus, sceneStatus, correct, reward, lessonId }));
        this.store$.dispatch(saveMove({ sceneId, answerId: lessonId, isCorrect: correct, points: reward, attempt }));  // Track answers
    }

    /* Menu */

    public openSideMenu() {
        this.menu.open();
    }

    public closeSideMenu() {
        this.menu.close();
    }

    public enableSidePane() {
        //console.log(`enableSidePane()`);
        this.store$.dispatch(setSidePaneEnabled());
    }

    public disableSidePane() {
        //console.log(`disableSidePane()`);
        this.store$.dispatch(setSidePaneDisabled());
    }

    /* Game Start */

    public startGame() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            if (game.gameStatus === EGameStatus.START) {
                this.closeSideMenu();
                this.store$.dispatch(postStartGameToServer());
                this.advanceGameState(EGameStatus.INTRO);
                this.navService.Intro();
            }
        })
    }

    /* Intro / First Rank */

    /*
    private advanceRank1State(currentStatus: ERankStatus) {
        if (currentStatus === ERankStatus.HIDE) {
            this.store$.dispatch(setRank1Status({ status: ERankStatus.QUESTION }));
        } else if (currentStatus === ERankStatus.QUESTION) {
            this.store$.dispatch(setRank1Status({ status: ERankStatus.RESULT }));
        }
    }
    */

    public advanceIntro() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            if (game.gameStatus === EGameStatus.INTRO) {
                if (game.rankLessons && game.rank1Status == ERankStatus.HIDE) {
                    this.enableSidePane();
                    this.store$.dispatch(setRank1Status({ status: ERankStatus.QUESTION }));
                    //this.advanceRank1State(game.rank1Status)
                    this.openSideMenu();
                } else {
                    this.closeSideMenu();
                    this.advanceGameState(EGameStatus.SCENE_QUESTION);
                    this.navService.FirstScene();
                }
            }
        })
    }

    public submitFirstRank() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            if (game.rank1Status === ERankStatus.QUESTION && game.rankSelection > 0) {
                this.closeSideMenu();
                this.store$.dispatch(saveRankBeginning({ rank: game.rankSelection }));
                this.store$.dispatch(setRank1Status({ status: ERankStatus.RESULT }));
                //this.advanceRank1State(game.rank1Status);
                this.advanceGameState(EGameStatus.SCENE_QUESTION);
                this.navService.FirstScene();
            }
        })
    }


    /* Scenes */

    public submitPreQuestion() {
        this.store$.select(getSelectedScene).pipe(take(1)).subscribe(scene => {
            if (scene.preQuestionAnswer == undefined && scene.preQuestionSelection != undefined) {
                const sceneId = scene.id;
                const answer = scene.preQuestionSelection;
                const isCorrect = scene.preQuestionCorrect ? scene.preQuestionCorrect == scene.preQuestionSelection : true;
                const points = 0;
                this.store$.dispatch(answerPreQuestion({ answer, unlockNext: scene.isCutScene }));
                this.store$.dispatch(saveExperience({ sceneId, answer, isCorrect, points }));  // Track answers
                if (scene.isCutScene) {
                    this.advanceSceneViewState(ESceneStatus.COMPLETE);
                } else {
                    this.advanceSceneViewState(scene.preQuestionCorrect == undefined ? ESceneStatus.QUESTION : ESceneStatus.PREQUESTION_RESULT);
                }
            }
        })
    }

    public acknowledgePreQuestion() {
        this.store$.select(getSelectedScene).pipe(take(1)).subscribe(scene => {
            if (scene.viewState == ESceneStatus.PREQUESTION_RESULT) {
                this.advanceSceneViewState(ESceneStatus.QUESTION);
            }
        })
    }

    public submitLesson() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            const scene: IScenePlayModel = game.scenes[game.selectedScene];
            const lesson: ILessonPlayModel = scene.lessonSelection;
            if (game.gameStatus == EGameStatus.SCENE_QUESTION && !scene.isSolved && lesson != undefined && scene.attempted.findIndex(item => item == lesson.id) < 0) {
                const correct = scene.id === lesson.id;
                const moves = game.moves - 1;
                const unsolved = this.getUnsolvedSceneCount(game.scenes) - (correct ? 1 : 0);
                const attempt = scene.attempted.length;

                if (unsolved > 0 && moves < unsolved) {
                    // Game Failed
                    this.closeSideMenu();
                    this.updateLesson(EGameStatus.FAILED, ESceneStatus.QUESTION_RESULT, correct, 0, scene.id, lesson.id, attempt);
                    this.navService.Failed();
                    this.postGameOver(false);
                } else {
                    if (correct) {
                        const reward = lesson.points;
                        if (unsolved <= 0) {
                            // Correct and scene round complete
                            this.updateLesson(EGameStatus.SCENE_RESULT, ESceneStatus.COMPLETE, correct, reward, scene.id, lesson.id, attempt);
                        } else {
                            // Correct
                            this.updateLesson(EGameStatus.SCENE_QUESTION, ESceneStatus.COMPLETE, correct, reward, scene.id, lesson.id, attempt);
                        }
                    } else {
                        // Lesson Wrong
                        this.updateLesson(EGameStatus.SCENE_QUESTION, ESceneStatus.QUESTION_RESULT, correct, 0, scene.id, lesson.id, attempt);
                    }
                }
            }
        })
    }

    public replayScene() {
        this.store$.select(getSelectedScene).pipe(take(1)).subscribe(scene => {
            if (scene.viewState == ESceneStatus.QUESTION_RESULT) {
                this.advanceSceneViewState(ESceneStatus.QUESTION);
            }
        })
    }

    public nextScene() {
        this.store$.select(getSelectedScene).pipe(take(1)).subscribe(scene => {
            if (scene.viewState == ESceneStatus.QUESTION_RESULT) {
                this.advanceSceneViewState(ESceneStatus.QUESTION);
                this.closeSideMenu();
                this.navService.NextScene();
            } if (scene.viewState == ESceneStatus.COMPLETE) {
                this.closeSideMenu();
                this.navService.NextScene();
            }
        })
    }

    /* Shortcut for testing */

    public cheatPastScenes() {
        this.store$.dispatch(cheatPlay());
        this.navService.NextScene();
    }

    /* Main game success and second rank */

    public advanceSuccess() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            if (game.gameStatus === EGameStatus.SCENE_RESULT) {
                if (game.rankLessons && game.rank2Status != ERankStatus.RESULT) {
                    this.enableSidePane();
                    this.advanceRank2State(game.rank2Status)
                    this.openSideMenu();
                } else {
                    this.closeSideMenu();
                    // Go to bonus if exists
                    if (game.bonusQuestions.length > 0) {
                        this.advanceGameState(EGameStatus.BONUS_QUESTION);
                        this.navService.Bonus();
                    } else {
                        this.postGameOver(true);
                        this.advanceGameState(EGameStatus.COMPLETE);
                        this.navService.Complete();
                    }
                }
            }
        })
    }

    public submitSecondRank() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            if (game.rank2Status === ERankStatus.QUESTION && game.rankSelection > 0) {
                this.store$.dispatch(saveRankEnd({ rank: game.rankSelection }));
                this.advanceRank2State(game.rank2Status);
            }
        })
    }

    /* Bonus questions */

    public submitBonusQuestion() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            const question: IBonusPlayModel = game.bonusQuestions[game.selectedBonus];
            if (question.viewState == EBonusStatus.QUESTION && question.selectedId != undefined) {
                if (question.isBetAllowed && game.bonusPoints > 0) {
                    // allow a bet on this question
                    this.store$.dispatch(setBonusStatus({ status: EBonusStatus.BET }));
                } else {
                    const correct: boolean = question.answerId == question.selectedId;
                    const reward: number = correct ? game.bonusDefaultAward : 0;
                    this.store$.dispatch(resolveBonus({ answer: question.selectedId, reward }));
                    this.store$.dispatch(saveBonus({ bonusId: question.id, answerId: question.selectedId, isCorrect: correct, bet: 0, points: reward }));  // Track answers
                }
            }
        })
    }

    public betCancel() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            const question: IBonusPlayModel = game.bonusQuestions[game.selectedBonus];
            if (question.isBetAllowed && question.viewState == EBonusStatus.BET) {
                this.store$.dispatch(setBonusStatus({ status: EBonusStatus.QUESTION }));
            }
        })
    }

    public betBonus() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            const question: IBonusPlayModel = game.bonusQuestions[game.selectedBonus];
            if (question.isBetAllowed && question.viewState == EBonusStatus.BET && question.selectedId != undefined) {
                const correct: boolean = question.answerId == question.selectedId;
                const reward: number = correct ? game.bonusDefaultAward + question.bet : -question.bet;
                this.store$.dispatch(resolveBonus({ answer: question.selectedId, reward }));
                this.store$.dispatch(saveBonus({ bonusId: question.id, answerId: question.selectedId, isCorrect: correct, bet: question.bet, points: reward }));  // Track answers
            }
        })
    }

    public advanceBonus() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            if (game.gameStatus === EGameStatus.BONUS_QUESTION) {
                const finished = game.bonusQuestions.filter(item => item.isSolved).length;
                if (game.bonusQuestions.length == finished) {
                    this.closeSideMenu();
                    this.postGameOver(true);
                    this.advanceGameState(EGameStatus.COMPLETE);
                    this.navService.Complete();
                } else {
                    this.closeSideMenu();
                    const index = this.getNextBonusIndex(game);
                    this.store$.dispatch(selectBonus({ index }));
                }
            } else if (game.gameStatus === EGameStatus.COMPLETE) {
                this.navService.Complete();
            }
        })
    }
}