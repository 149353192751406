import { IAttachmentModel } from './attachment-model';

export enum EBonusStatus {
    QUESTION = "QUESTION",
    BET = "BET",
    COMPLETE = "COMPLETE"
}

export interface IBonusPlayModel {
    id: number;
    title: string;
    question: string;
    sceneOrLesson: string
    choices: number[];
    answerId: number;
    isBetAllowed: boolean;
    reasoning: string;
    reasoningAttachments?: IAttachmentModel[];

    viewState: EBonusStatus;
    isVisible: boolean;
    selectedId: number;
    guess: number;
    bet: number;
    //rank: number[];
    isSolved: boolean;
    bonusPoints: number;
}
