import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SceneSideComponent } from './scene-side.component';
import { LessonCardComponentModule } from '../lesson-card/lesson-card.module';
import { OptionCardComponentModule } from '../option-card/option-card.module';
import { ContentScrollerComponentModule } from '../content-scroller/content-scroller.module';
import { SoloScoreComponentModule } from '../solo-score/solo-score.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        LessonCardComponentModule,
        OptionCardComponentModule,
        ContentScrollerComponentModule,
        SoloScoreComponentModule
    ],
    declarations: [SceneSideComponent],
    exports: [SceneSideComponent]
})
export class SceneSideComponentModule { }
