import { Action, createReducer, on, State } from '@ngrx/store';

import { ITrackState, initialTrackState } from '../state/track.state';
import { TrackActions } from "../actions";

const reducer = createReducer(
    initialTrackState,
    // saveRankBeginning
    on(TrackActions.saveRankBeginning, (state, { rank }) => ({
        ...state,
        rankBeginning: rank
    })),
    // saveRankEnd
    on(TrackActions.saveRankEnd, (state, { rank }) => ({
        ...state,
        rankEnd: rank
    })),
    // saveMove
    on(TrackActions.saveMove, (state, { sceneId, answerId, isCorrect, points, attempt }) => ({
        ...state,
        moves: [...state.moves,
        {
            seq: state.moves.length + 1,
            sceneId: sceneId,
            answerId: answerId,
            isCorrect: isCorrect,
            points: points,
            attempt: attempt
        }]
    })),
    // saveBonus
    on(TrackActions.saveBonus, (state, { bonusId, answerId, isCorrect, bet, points }) => ({
        ...state,
        bonus: [...state.bonus,
        {
            seq: state.bonus.length + 1,
            bonusId: bonusId,
            answerId: answerId,
            isCorrect: isCorrect,
            bet: bet,
            points: points
        }]
    })),
    // saveExperience
    on(TrackActions.saveExperience, (state, { sceneId, answer, isCorrect, points }) => ({
        ...state,
        experience: [...state.experience,
        {
            seq: state.experience.length + 1,
            sceneId: sceneId,
            answer: answer,
            isCorrect: isCorrect,
            points: points
        }]
    })),
);

export function trackReducer(state: ITrackState, action: Action) {
    return reducer(state, action);
}
