import { Action, createReducer, on } from '@ngrx/store';

import { IPlayState, initialPlayState } from '../state/play.state';
import { PlayActions } from "../actions";
import { EBonusStatus, EGameStatus } from 'src/app/models';
import { buildGameFromStory, isValidStory } from '../service/play.service';

const reducer = createReducer(
    initialPlayState,
    // loadGameFromStory
    on(PlayActions.loadGameFromStory, (state, { story, playMode }) => ({
        ...state,
        game: buildGameFromStory(story, playMode),
        loaded: isValidStory(story)
    })),
    // loadGameSuccess
    on(PlayActions.loadGameSuccess, (state, { game }) => ({
        ...state,
        game: game,
        loaded: game !== undefined
    })),
    // loadGameFailure
    on(PlayActions.loadGameFailure, (state, { error }) => ({
        ...state,
        error: error
    })),
    // giveBonus
    on(PlayActions.giveBonus, (state) => ({
        ...state,
        game: {
            ...state.game,
            bonusPoints: state.game.bonusPoints + state.game.bonusDefaultAward
        }
    })),
    // startGame
    on(PlayActions.startGame, (state, { historyId }) => ({
        ...state,
        gameStarted: true,
        game: {
            ...state.game,
            historyId: historyId
        }
    })),
    // setMenuShowing
    on(PlayActions.setMenuShowing, (state, { showing }) => ({
        ...state,
        isMenuShowing: showing
    })),
    // setSidePaneDisabled
    on(PlayActions.setSidePaneDisabled, (state, { }) => ({
        ...state,
        sidePaneDisabled: true
    })),
    // setSidePaneEnabled
    on(PlayActions.setSidePaneEnabled, (state, { }) => ({
        ...state,
        sidePaneDisabled: false
    })),
    // setRank1Status
    on(PlayActions.setRank1Status, (state, { status }) => ({
        ...state,
        game: {
            ...state.game,
            rank1Status: status
        }
    })),
    // setRank2Status
    on(PlayActions.setRank2Status, (state, { status }) => ({
        ...state,
        game: {
            ...state.game,
            rank2Status: status
        }
    })),
    // setRankSelection
    on(PlayActions.setRankSelection, (state, { id }) => ({
        ...state,
        game: {
            ...state.game,
            rankSelection: state.game.rankSelection == id ? 0 : id
        }
    })),
    // setSceneViewState
    on(PlayActions.setSceneViewState, (state, { status }) => ({
        ...state,
        game: {
            ...state.game,
            scenes: [
                ...state.game.scenes.map((_, index) => {
                    if (index == state.game.selectedScene) {
                        return {
                            ...state.game.scenes[index],
                            viewState: status
                        }
                    } else {
                        return state.game.scenes[index];
                    }
                })
            ]
        }
    })),
    // answerPreQuestion
    on(PlayActions.answerPreQuestion, (state, { answer, unlockNext }) => ({
        ...state,
        game: {
            ...state.game,
            scenes: [
                ...state.game.scenes.map((scene, index) => {
                    if (index == state.game.selectedScene) {
                        return {
                            ...state.game.scenes[index],
                            preQuestionAnswer: answer,
                            isSolved: scene.isCutScene
                        }
                    } else if (unlockNext && index == state.game.selectedScene + 1) {
                        return {
                            ...state.game.scenes[index],
                            isVisible: true
                        }
                    } else {
                        return state.game.scenes[index];
                    }
                })
            ]
        }
    })),
    // setSceneOptionSelection
    on(PlayActions.setSceneOptionSelection, (state, { option }) => ({
        ...state,
        game: {
            ...state.game,
            scenes: [
                ...state.game.scenes.map((_, index) => {
                    if (index == state.game.selectedScene) {
                        return {
                            ...state.game.scenes[index],
                            preQuestionSelection: state.game.scenes[index].preQuestionSelection == option ? undefined : option
                        }
                    } else {
                        return state.game.scenes[index];
                    }
                })
            ]
        }
    })),
    // setSceneLessonSelection
    on(PlayActions.setSceneLessonSelection, (state, { lesson }) => ({
        ...state,
        game: {
            ...state.game,
            scenes: [
                ...state.game.scenes.map((_, index) => {
                    if (index == state.game.selectedScene) {
                        return {
                            ...state.game.scenes[index],
                            lessonSelection: state.game.scenes[index].lessonSelection == lesson ? undefined : lesson
                        }
                    } else {
                        return state.game.scenes[index];
                    }
                })
            ]
        }
    })),
    // unlock next scene

    // updateAfterGuess
    on(PlayActions.updateAfterGuess, (state, { gameStatus, sceneStatus, correct, reward, lessonId }) => ({
        ...state,
        game: {
            ...state.game,
            moves: state.game.moves - 1,
            points: state.game.points + reward,
            gameStatus: gameStatus,
            // add extra 20 (bonusDefaultAward*2) for finishing main round. This ensures some bonus points for betting
            bonusPoints: gameStatus === "SCENE_RESULT" ? state.game.bonusPoints + (state.game.bonusDefaultAward * 2) + ((state.game.moves - 1) * state.game.bonusDefaultAward) : state.game.bonusPoints,
            scenes: [
                ...state.game.scenes.map((_, index) => {
                    if (index == state.game.selectedScene) {
                        if (correct) {
                            return {
                                ...state.game.scenes[index],
                                isSolved: correct,
                                viewState: sceneStatus,
                                points: reward
                            }
                        } else {
                            return {
                                ...state.game.scenes[index],
                                isSolved: correct,
                                viewState: sceneStatus,
                                attempted: [
                                    ...state.game.scenes[index].attempted,
                                    lessonId
                                ]
                            }
                        }
                    } else if (index == state.game.selectedScene + 1) {
                        return {
                            ...state.game.scenes[index],
                            isVisible: true
                        }
                    } else {
                        return state.game.scenes[index];
                    }
                })
            ],
            lessons: correct ? [
                ...state.game.lessons.map((item, index) => {
                    if (item.id == lessonId) {
                        return {
                            ...state.game.lessons[index],
                            solved: true
                        }
                    } else {
                        return state.game.lessons[index];
                    }
                }).sort((a, b) => {
                    if (a.solved < b.solved) return -1;
                    if (a.solved > b.solved) return 1;
                    return 0;
                })
            ] : [
                    ...state.game.lessons.map((item, index) => {
                        if (item.id == lessonId) {
                            return {
                                ...state.game.lessons[index],
                                points: state.game.lessons[index].points == 50 ? 25 :
                                    state.game.lessons[index].points == 25 ? 10 : 0
                            }
                        } else {
                            return state.game.lessons[index];
                        }
                    })
                ]
        }
    })),
    // selectScene
    on(PlayActions.selectScene, (state, { index }) => ({
        ...state,
        game: {
            ...state.game,
            selectedScene: state.game.scenes[index].isVisible ? index : state.game.selectedScene
        }
    })),
    // unlockNextScene
    on(PlayActions.unlockNextScene, (state, { }) => ({
        ...state,
        game: {
            ...state.game,
            scenes: [
                ...state.game.scenes.map((_, index) => {
                    if (index == state.game.selectedScene + 1) {
                        return {
                            ...state.game.scenes[index],
                            isVisible: true
                        }
                    } else {
                        return state.game.scenes[index];
                    }
                })
            ]
        }
    })),
    // solveScene
    on(PlayActions.solveScene, (state, { }) => ({
        ...state,
        game: {
            ...state.game,
            scenes: [
                ...state.game.scenes.map((_, index) => {
                    if (index == state.game.selectedScene) {
                        return {
                            ...state.game.scenes[index],
                            isSolved: true,
                        }
                    } else {
                        return state.game.scenes[index];
                    }
                })
            ]
        }
    })),
    // setStatus
    on(PlayActions.setStatus, (state, { status }) => ({
        ...state,
        game: {
            ...state.game,
            gameStatus: status
        }
    })),
    // selectBonus
    on(PlayActions.selectBonus, (state, { index }) => ({
        ...state,
        game: {
            ...state.game,
            selectedBonus: state.game.bonusQuestions[index].isVisible ? index : state.game.selectedBonus
        }
    })),
    // setBonusSelection
    on(PlayActions.setBonusSelection, (state, { sceneId }) => ({
        ...state,
        game: {
            ...state.game,
            bonusQuestions: [
                ...state.game.bonusQuestions.map((_, index) => {
                    if (index == state.game.selectedBonus) {
                        return {
                            ...state.game.bonusQuestions[index],
                            selectedId: sceneId == state.game.bonusQuestions[index].selectedId ? undefined : sceneId
                        }
                    } else {
                        return state.game.bonusQuestions[index];
                    }
                }),
            ]
        }
    })),
    //setBonusStatus
    on(PlayActions.setBonusStatus, (state, { status }) => ({
        ...state,
        game: {
            ...state.game,
            bonusQuestions: [
                ...state.game.bonusQuestions.map((_, index) => {
                    if (index == state.game.selectedBonus) {
                        return {
                            ...state.game.bonusQuestions[index],
                            viewState: status
                        }
                    } else {
                        return state.game.bonusQuestions[index];
                    }
                }),
            ]
        }
    })),
    // setBonusBet
    on(PlayActions.setBonusBet, (state, { bet }) => ({
        ...state,
        game: {
            ...state.game,
            bonusQuestions: [
                ...state.game.bonusQuestions.map((_, index) => {
                    if (index == state.game.selectedBonus) {
                        return {
                            ...state.game.bonusQuestions[index],
                            bet: bet
                        }
                    } else {
                        return state.game.bonusQuestions[index];
                    }
                }),
            ]
        }
    })),
    // resolveBonus
    on(PlayActions.resolveBonus, (state, { answer, reward }) => ({
        ...state,
        game: {
            ...state.game,
            bonusPoints: state.game.bonusPoints + (reward !== undefined ? reward : 0),

            bonusQuestions: [
                ...state.game.bonusQuestions.map((_, index) => {
                    if (index == state.game.selectedBonus) {
                        return {
                            ...state.game.bonusQuestions[index],
                            isSolved: reward !== undefined,
                            guess: answer,
                            bonusPoints: reward !== undefined ? reward : 0,
                            viewState: EBonusStatus.COMPLETE
                        }
                    } else if (index == state.game.selectedBonus + 1) {
                        return {
                            ...state.game.bonusQuestions[index],
                            isVisible: reward !== undefined
                        }
                    } else {
                        return state.game.bonusQuestions[index];
                    }
                }),
            ]
        }
    })),
    // cheatPlay
    on(PlayActions.cheatPlay, (state) => ({
        ...state,
        game: {
            ...state.game,
            scenes: [
                ...state.game.scenes.map((_, index) => {
                    return {
                        ...state.game.scenes[index],
                        isVisible: true,
                        isSolved: true
                    }
                })
            ],
            points: 100,
            bonusPoints: 25,
            moves: 0,
            selectedScene: state.game.scenes.length - 1,
            gameStatus: EGameStatus.SCENE_RESULT
        }
    })),

);

export function playReducer(state: IPlayState, action: Action) {
    return reducer(state, action);
}

