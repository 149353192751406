import { Router, CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IAppState } from '../store/state/app.state';

import { getGameLoaded } from '../store/selectors/play.selector';
import { facilitationLoadRequired } from '../store/actions/facilitation.actions';

@Injectable({
    providedIn: 'root',
})
export class FacilitationLoadedGuard implements CanActivate {

    constructor(private store: Store<IAppState>, private router: Router) {
    }

    canActivate(): Observable<boolean> {
        return this.store.pipe(
            select(getGameLoaded),
            map(gameLoaded => {
                if (!gameLoaded) {
                    this.store.dispatch(facilitationLoadRequired());
                    return false;
                }
                return true;
            })
        );
    }

}
