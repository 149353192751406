import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChoiceCardComponent } from './choice-card.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [ChoiceCardComponent],
    exports: [ChoiceCardComponent]
})
export class ChoiceCardComponentModule { }
