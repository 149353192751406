import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { getSecondRankQuestion, getRemainingLessons } from '../../store/selectors/play.selector';
import { setRankSelection } from '../../store/actions/play.actions';

@Component({
  selector: 'app-success-side',
  templateUrl: './success-side.component.html',
  styleUrls: ['./success-side.component.scss'],
})
export class SuccessSideComponent implements OnInit {

  question = this.store$.pipe(select(getSecondRankQuestion));
  lessons = this.store$.pipe(select(getRemainingLessons));

  constructor(private store$: Store<IAppState>) { }

  onSelectionChange(id: number) {
    this.store$.dispatch(setRankSelection({ id }));
  }

  ngOnInit() { }

}
