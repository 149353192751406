import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ModalContentPage } from '../../pages/modal-content/modal-content.page';
import { IAttachmentModel } from '../../models/attachment-model';

@Component({
  selector: 'app-content-scroller',
  templateUrl: './content-scroller.component.html',
  styleUrls: ['./content-scroller.component.scss'],
})
export class ContentScrollerComponent implements OnInit {
  @Input() name: string;
  @Input() content: IAttachmentModel[] = undefined;

  constructor(public modalController: ModalController) { }

  getItemName(index: number): string {
    const suffix = index.toString();
    return `${this.name}-${suffix}`;
  }

  scrollToImage(index: number) {
    document.getElementById(this.getItemName(index)).scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    });
  }

  async presentModal() {
    const modal = await this.modalController.create({
      component: ModalContentPage,
      componentProps: {
        'content': this.content
      },
      swipeToClose: true,
      backdropDismiss: false,
      cssClass: 'my-custom-class'
    });
    return await modal.present();
  }

  ngOnInit() { }

}
