import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-option-card',
  templateUrl: './option-card.component.html',
  styleUrls: ['./option-card.component.scss'],
})
export class OptionCardComponent implements OnInit {
  @Input() option: string;
  @Input() highLight: boolean = false;
  @Input() disabled: boolean = false;
  @Output() selected = new EventEmitter<string>();

  constructor() { }

  onSelected(option: string) {
    this.selected.emit(option)
  }

  ngOnInit() { }

}
