import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-start-side',
  templateUrl: './start-side.component.html',
  styleUrls: ['./start-side.component.scss'],
})
export class StartSideComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
