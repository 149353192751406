import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GameLoadedGuard } from './guards/gameLoaded.guard';
import { FacilitationLoadedGuard } from './guards/facilitationLoaded.guard';

const routes: Routes = [
  /* LAUNCH FROM PLATFORM */
  {
    path: 'launch/:id',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'play/:id',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
  },
  {
    path: 'teach/:id',
    loadChildren: () => import('./pages/facilitation-start/facilitation-start.module').then(m => m.FacilitationStartPageModule)
  },
  {
    path: 'learn/:id',
    loadChildren: () => import('./pages/learner-start/learner-start.module').then(m => m.LearnerStartPageModule)
  },
  // Start Solo Game
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then(m => m.StartPageModule)
  },
  // Start Facilitation
  {
    path: 'facilitation-start',
    loadChildren: () => import('./pages/facilitation-start/facilitation-start.module').then(m => m.FacilitationStartPageModule)
  },
  // Join a Facilitation
  {
    path: 'learner-start',
    loadChildren: () => import('./pages/learner-start/learner-start.module').then(m => m.LearnerStartPageModule)
  },

  /* SOLO ROUTES */
  {
    path: 'intro',
    canActivate: [GameLoadedGuard],
    loadChildren: () => import('./pages/intro/intro.module').then(m => m.IntroPageModule)
  },
  {
    path: 'scene/:id',
    canActivate: [GameLoadedGuard],
    loadChildren: () => import('./pages/scene/scene.module').then(m => m.ScenePageModule)
  },
  {
    path: 'bonus',
    canActivate: [GameLoadedGuard],
    loadChildren: () => import('./pages/bonus/bonus.module').then(m => m.BonusPageModule)
  },
  {
    path: 'success',
    canActivate: [GameLoadedGuard],
    loadChildren: () => import('./pages/success/success.module').then(m => m.SuccessPageModule)
  },
  {
    path: 'fail',
    canActivate: [GameLoadedGuard],
    loadChildren: () => import('./pages/fail/fail.module').then(m => m.FailPageModule)
  },
  {
    path: 'complete',
    canActivate: [GameLoadedGuard],
    loadChildren: () => import('./pages/complete/complete.module').then(m => m.CompletePageModule)
  },

  /* Facilitation Routes */
  {
    path: 'facilitation-teams',
    canActivate: [FacilitationLoadedGuard],
    loadChildren: () => import('./pages/facilitation-add-teams/facilitation-add-teams.module').then(m => m.FacilitationAddTeamsPageModule)
  },
  {
    path: 'facilitation-intro',
    canActivate: [FacilitationLoadedGuard],
    loadChildren: () => import('./pages/facilitation-intro/facilitation-intro.module').then(m => m.FacilitationIntroPageModule)
  },
  {
    path: 'facilitation-scene/:id',
    canActivate: [FacilitationLoadedGuard],
    loadChildren: () => import('./pages/facilitation-scene/facilitation-scene.module').then(m => m.FacilitationScenePageModule)
  },

  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'facilitation*',
    redirectTo: 'start'
  },
  {
    path: '**',
    redirectTo: 'start'
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
