import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { take } from 'rxjs/operators';
import { IFacilitationPlayModel, EFacilitationStatus, ILearnerModel, ITeamPlayModel, IScenePlayModel } from '../../models';
import { getClassroom, getCurrentFacilitationScene, getCurrentTurn, ICurrentTurn } from '../selectors/facilitation.selector';
import { NavService } from './nav.service';
import { addLearnerToTeam, addTeam, addTeamPoints, removeTeam, setStatus, startVote, clearVotes, stopVote, updateVote, updateTeamScores } from '../actions/facilitation.actions';
import { AlertController } from '@ionic/angular';
import { unlockNextScene, solveScene } from '../actions/play.actions';

@Injectable({
    providedIn: 'root',
})
export class FacilitationService {

    constructor(private store$: Store<IAppState>,
        public alertController: AlertController,
        //private menu: MenuController,
        private navService: NavService
        //private securityService: SecurityService
    ) { }


    private _DB_Update_Status(status: EFacilitationStatus, sceneId: number = -1) {
        const data: IFacilitationPlayModel = {
            id: 0,
            description: '',
            syncCode: '',
            status: status,
            teams: [],
            turns: [],
            currentSceneId: sceneId,
            currentTurnIndex: -1
        }
        console.log(`_DB_Update_Status(${data.status})`);
        localStorage.setItem('Facilitation', JSON.stringify(data));
    }

    private _DB_Create_Teams(teams: ITeamPlayModel[]) {
        const data: IFacilitationPlayModel = {
            id: 0,
            description: '',
            syncCode: '',
            status: EFacilitationStatus.JOIN,
            teams: teams,
            turns: [],
            currentSceneId: -1,
            currentTurnIndex: -1
        }
        console.log(`_DB_Create_Teams(${data.status})`);
        localStorage.setItem('Facilitation', JSON.stringify(data));
    }

    private getInitials(name: string) {
        var names = name.split(' '),
            initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    public checkStatus() {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            switch (classroom.status) {
                case EFacilitationStatus.JOIN:
                    const data = JSON.parse(localStorage.getItem('learner'));
                    if (data) {
                        const learner: ILearnerModel = {
                            id: 1,
                            name: data.name,
                            initials: this.getInitials(data.name),
                            userId: data.userId,
                            firstVoteId: undefined,
                            firstVoteString: '',
                            secondVoteId: undefined,
                            secondVoteString: ''
                        }
                        const teamId = data.teamId;
                        this.store$.dispatch(addLearnerToTeam({ learner, teamId }));
                        //localStorage.removeItem('learner');
                    }
                    break;
                default:
            }
        })
    }

    public startNewFacilitation() {
        this._DB_Update_Status(EFacilitationStatus.NEW);
    }

    public proceedToAddTeams() {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            if (classroom.status === EFacilitationStatus.NEW) {
                this.navService.AddTeams();
            }
        })
    }

    public addTeam(teamName: string) {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            if (classroom.teams.find(team => team.teamName == teamName) == undefined) {
                this.store$.dispatch(addTeam({ teamName }));
            }
        })
    }

    public removeTeam(team: ITeamPlayModel) {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            this.store$.dispatch(removeTeam({ team }));
        })
    }

    public proceedToJoin() {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            if (classroom.status === EFacilitationStatus.NEW) {

                // TESTING: with localStorage, add API to save teams to database
                this._DB_Create_Teams(classroom.teams);

                this.store$.dispatch(setStatus({ status: EFacilitationStatus.JOIN }));
            }
        })
    }


    private addTestPlayers(adder: number) {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            classroom.teams.map(team => {
                const name = `Player ${team.id + adder}`;
                const learner: ILearnerModel = {
                    id: team.id + adder,
                    name: name,
                    initials: this.getInitials(name),
                    userId: team.id + adder,
                    firstVoteId: undefined,
                    firstVoteString: '',
                    secondVoteId: undefined,
                    secondVoteString: ''
                }
                this.store$.dispatch(addLearnerToTeam({ learner, teamId: team.id }));
            });
        })
    }

    public addDemoTeams() {
        this.addTeam('Team Alpha');
        this.addTeam('Team Beta');
        this.addTeam('Team Charlie');
    }

    public addDemoPlayers() {
        this.addTestPlayers(10);
        this.addTestPlayers(20);
        this.addTestPlayers(30);
    }

    /*
    public skipJoinForTesting() {
        this.store$.dispatch(addTeam({ teamName: "Team Alpha" }));
        this.store$.dispatch(addTeam({ teamName: "Team Beta" }));
        this.store$.dispatch(addTeam({ teamName: "Team Charlie" }));
        this.store$.dispatch(addTeam({ teamName: "Team Delta" }));
        this.store$.dispatch(addTeam({ teamName: "Team Echo" }));
        this.addTestPlayers(10);
        this.addTestPlayers(20);
        this.addTestPlayers(30);
        this.store$.dispatch(setStatus({ status: EFacilitationStatus.READY }));
        this.navService.Intro();
    }
    */

    public proceedToIntro() {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            if (classroom.status === EFacilitationStatus.JOIN) {

                // TESTING: with localStorage, add API to save teams to database
                this._DB_Update_Status(EFacilitationStatus.READY);

                this.store$.dispatch(setStatus({ status: EFacilitationStatus.READY }));

                this.navService.Intro();
            }
        })
    }

    public advanceCutScene() {
        this.store$.dispatch(unlockNextScene());
        this.solveScene();
        this.navService.SkipToNextScene();
    }

    public solveScene() {
        this.store$.dispatch(solveScene());
    }

    public unlockNextScene() {
        this.store$.dispatch(unlockNextScene());
    }

    public resumeClassroom() {
        /*
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
        })
        */
    }

    public addPoints(team: ITeamPlayModel) {
        this.store$.dispatch(addTeamPoints({ team, points: 2 }));
    }

    public removePoints(team: ITeamPlayModel) {
        this.store$.dispatch(addTeamPoints({ team, points: -2 }));
    }

    public startFirstVote() {
        this.store$.select(getCurrentFacilitationScene).pipe(take(1)).subscribe(currentScene => {
            if (currentScene.isVoteAvailable) {
                this.store$.dispatch(clearVotes());
                this.store$.dispatch(startVote({ sceneId: currentScene.scene.id, isSecondVote: false }));
            }
        })
    }

    public startSecondVote(sceneId: number) {
        this.store$.dispatch(startVote({ sceneId, isSecondVote: true }));
    }

    public makeUpVotes(allCorrect: boolean = false) {
        this.store$.select(getCurrentTurn).pipe(take(1)).subscribe(currentTurn => {
            if (currentTurn.isVoteActive) {
                currentTurn.teams.map(team => {
                    team.learners.map(learner => {
                        if (allCorrect) {
                            const voteSceneId: number = currentTurn.currentScene.id;
                            const voteSceneString: string = currentTurn.currentScene.keyword;
                            //this.store$.dispatch(updateVote({ turnId: currentTurn.turn.id, learnerId: learner.id, voteSceneId }));
                            //console.log(`updateVote(${learner.id}, ${voteSceneId}, ${voteSceneString})`);
                            this.store$.dispatch(updateVote({ learnerId: learner.id, voteSceneId, voteSceneString }));
                        } else {
                            const voteLesson = currentTurn.lessons[Math.floor(Math.random() * currentTurn.lessons.length)];
                            const voteSceneId: number = voteLesson.id;
                            const voteSceneString: string = voteLesson.keyword;
                            this.store$.dispatch(updateVote({ learnerId: learner.id, voteSceneId, voteSceneString }));
                        }
                    })
                })
            }
        })
    }

    private async handleSelectVote(learner: ILearnerModel, buttons: any) {
        const alert = await this.alertController.create({
            header: 'Select Vote',
            message: learner.name,
            buttons: buttons
        });
        await alert.present();
    }

    public processAddLearnerVote(learner: ILearnerModel, voteSceneId: number, voteSceneString: string) {
        this.store$.select(getCurrentTurn).pipe(take(1)).subscribe(currentTurn => {
            if (currentTurn.isVoteActive) {
                //this.store$.dispatch(updateVote({ turnId: currentTurn.turn.id, learnerId: learner.id, voteSceneId: vote }));
                this.store$.dispatch(updateVote({ learnerId: learner.id, voteSceneId, voteSceneString }));
            }
        })
    }

    public addLearnerVote(learner: ILearnerModel) {
        this.store$.select(getCurrentTurn).pipe(take(1)).subscribe(currentTurn => {
            if (currentTurn.isVoteActive) {
                let buttons = [];
                currentTurn.lessons.map(item => {
                    buttons.push({
                        text: item.keyword,
                        cssClass: 'primary',
                        handler: () => {
                            this.processAddLearnerVote(learner, item.id, item.keyword);
                        }
                    })
                });
                buttons.push({
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => { }
                })
                this.handleSelectVote(learner, buttons);
            }
        })
    }

    private async handleConfirmVote() {
        const alert = await this.alertController.create({
            header: 'Stop Voting?',
            message: 'Are you sure. Not all votes are in.',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => { }
                }, {
                    text: 'Yes',
                    handler: () => {
                        this.processStopVote();
                    }
                }
            ]
        });
        await alert.present();
    }

    private countCorrectVotes(currentTurn: ICurrentTurn): number {
        const correctId: number = currentTurn.currentScene.id;
        const correct = currentTurn.teams.reduce((total, team) => {
            return total + team.learners.filter(learner => {
                if (currentTurn.status == EFacilitationStatus.VOTE2) {
                    if (learner.secondVoteId != undefined) {
                        return learner.secondVoteId == correctId;
                    } else {
                        return learner.firstVoteId == correctId;
                    }
                } else {
                    return learner.firstVoteId == correctId;
                }
            }).length;
        }, 0);
        return correct;
    }

    private processStopVote() {
        this.store$.select(getCurrentTurn).pipe(take(1)).subscribe(currentTurn => {
            console.log(`processStopVote(${currentTurn.status})`);
            if (currentTurn.status == EFacilitationStatus.VOTE1) {
                // First Vote
                const correctVotes = this.countCorrectVotes(currentTurn);
                console.log(`correctVotes=${correctVotes}`);

                switch (correctVotes) {
                    case 0:
                        // All wrong
                        this.store$.dispatch(stopVote({ status: EFacilitationStatus.RESULT_VOTE1_WRONG }));
                        break;
                    case currentTurn.learnerCount:
                        // All correct
                        this.store$.dispatch(stopVote({ status: EFacilitationStatus.RESULT_VOTE1_CORRECT }));
                        this.awardTeamPoints(currentTurn);
                        break;
                    default:
                        // Debate
                        this.store$.dispatch(stopVote({ status: EFacilitationStatus.RESULT_DEBATE }));
                }

            } else if (currentTurn.status == EFacilitationStatus.VOTE2) {
                // Second Vote
                const correctVotes = this.countCorrectVotes(currentTurn);
                //console.log(`correctVotes=${correctVotes}`);

                switch (correctVotes) {
                    case 0:
                        // All wrong
                        this.store$.dispatch(stopVote({ status: EFacilitationStatus.RESULT_VOTE1_WRONG }));
                        break;
                    case currentTurn.learnerCount:
                        // All correct
                        this.store$.dispatch(stopVote({ status: EFacilitationStatus.RESULT_VOTE1_CORRECT }));
                        this.awardTeamPoints(currentTurn);
                        break;
                    default:
                        // Finalize
                        this.store$.dispatch(stopVote({ status: EFacilitationStatus.RESULT_FINAL }));
                        this.awardTeamPoints(currentTurn);
                }
            }
        })
    }

    public async stopVote() {
        this.store$.select(getCurrentTurn).pipe(take(1)).subscribe(currentTurn => {
            if (currentTurn.isVoteActive) {
                // check if all votes are in
                if (currentTurn.areAllVotesIn) {
                    this.processStopVote();
                } else {
                    this.handleConfirmVote();
                }
            }
        })
    }

    public advanceResult() {
        this.store$.select(getCurrentTurn).pipe(take(1)).subscribe(currentTurn => {
            switch (currentTurn.status) {
                case EFacilitationStatus.RESULT_VOTE1_WRONG:
                    this.store$.dispatch(setStatus({ status: EFacilitationStatus.READY }));
                    this.unlockNextScene();
                    this.navService.Map();
                    break;
                case EFacilitationStatus.RESULT_VOTE1_CORRECT:
                case EFacilitationStatus.RESULT_FINAL:
                    this.solveScene();
                    this.unlockNextScene();
                    this.store$.dispatch(setStatus({ status: EFacilitationStatus.READY }));
                    break;
                case EFacilitationStatus.RESULT_DEBATE:
                    this.startSecondVote(currentTurn.currentScene.id);
                    break;

                default:
            }
        })
    }

    private calculateTeamScores(currentTurn: ICurrentTurn): { teamId: number, points: number }[] {
        let teamPointChange: { teamId: number, points: number }[] = [];
        const correctId: number = currentTurn.currentScene.id;

        currentTurn.teams.map(team => {
            const numLearners = team.learners.length;
            const numCorrect = team.learners.filter(learner => {
                if (learner.secondVoteId != undefined) {
                    return learner.secondVoteId == correctId;
                } else {
                    return learner.firstVoteId == correctId;
                }
            }).length;

            //console.log(`calculateTeamScores(${team.teamName}): numLearners=${numLearners},  numCorrect=${numCorrect}`)

            const points: number = Math.floor(12 * (numCorrect / numLearners));

            teamPointChange.push({ teamId: team.id, points })
        })

        return teamPointChange;
    }

    private awardTeamPoints(currentTurn: ICurrentTurn) {
        const teamPointChange = this.calculateTeamScores(currentTurn);
        this.store$.dispatch(updateTeamScores({ teamPointChange }));
    }

}