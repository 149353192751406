import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { StoreModule, Store } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducers } from './store/reducers';
import { appEffects } from './store/effects';
import { CookieService } from 'ngx-cookie-service';

// guards
import { GameLoadedGuard } from './guards/gameLoaded.guard';
import { GameStartedGuard } from './guards/gameStarted.guard';
import { FacilitationLoadedGuard } from './guards/facilitationLoaded.guard';

// game header
import { GameHeaderComponent } from './components/game-header/game-header.component';

// side components
import { StartSideComponent } from './components/start-side/start-side.component';
import { IntroSideComponentModule } from './components/intro-side/intro-side.module';
import { SceneSideComponentModule } from './components/scene-side/scene-side.module';
import { SuccessSideComponentModule } from './components/success-side/success-side.module';
import { BonusSideComponentModule } from './components/bonus-side/bonus-side.module';
import { LeaderBoardComponentModule } from './components/leader-board/leader-board.module';

import { SideButtonComponentModule } from './components/side-button/side-button.module';
import { SideHeaderComponent } from './components/side-header/side-header.component';

// Modals
import { ModalMapPageModule } from './pages/modal-map/modal-map.module';
import { ModalContentPageModule } from './pages/modal-content/modal-content.module';
import { ModalHelpPageModule } from './pages/modal-help/modal-help.module';


import { SoloScoreComponentModule } from './components/solo-score/solo-score.module';


@NgModule({
  declarations: [
    AppComponent,
    StartSideComponent,
    SideHeaderComponent,
    GameHeaderComponent
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ mode: 'ios' }),
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(appReducers),
    EffectsModule.forRoot(appEffects),
    IntroSideComponentModule,
    SceneSideComponentModule,
    SuccessSideComponentModule,
    BonusSideComponentModule,
    SideButtonComponentModule,
    LeaderBoardComponentModule,
    ModalMapPageModule,
    ModalContentPageModule,
    ModalHelpPageModule,
    SoloScoreComponentModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GameLoadedGuard,
    GameStartedGuard,
    FacilitationLoadedGuard,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
