import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MapHexComponent } from './map-hex.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [MapHexComponent],
    exports: [MapHexComponent]
})
export class MapHexComponentModule { }
