import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IScenePlayModel } from '../../models/scene-play-model';

@Component({
  selector: 'app-choice-card',
  templateUrl: './choice-card.component.html',
  styleUrls: ['./choice-card.component.scss'],
})
export class ChoiceCardComponent implements OnInit {
  @Input() scene: IScenePlayModel = undefined;
  @Input() disabled: boolean = false;
  @Input() showLesson: boolean = true;
  @Input() highLight: boolean = false;
  @Output() selected = new EventEmitter<IScenePlayModel>();

  expand: boolean = false;

  constructor() { }

  onSelected(scene: IScenePlayModel) {
    this.selected.emit(scene)
  }

  toggleExpand(event: any) {
    event.stopPropagation();
    this.expand = !this.expand;
  }

  ngOnInit() { }

}
