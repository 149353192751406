import { IAttachmentModel } from './attachment-model';
import { ILessonPlayModel } from './lesson-play-model';

export enum ESceneStatus {
    PREQUESTION = "PREQUESTION",
    PREQUESTION_RESULT = "PREQUESTION_RESULT",
    QUESTION = "QUESTION",
    QUESTION_RESULT = "QUESTION_RESULT",
    COMPLETE = "COMPLETE"
}

export interface IScenePlayModel {
    id: number;
    shortTitle: string;
    title: string;
    description: string;
    lessonPrompt: string;
    keyword: string;
    lesson: string;
    reflection: string;
    isCutScene: boolean;
    isBogus: boolean;
    attachments?: IAttachmentModel[];
    reflectionAttachments?: IAttachmentModel[];
    preQuestion?: string;
    preQuestionOptions?: string[];
    preQuestionCorrect?: string;
    preQuestionResponse?: string;

    viewState: ESceneStatus;
    isVisible: boolean;
    preQuestionAnswer: string;
    attempted: number[];
    isSolved: boolean;
    points: number;
    bonusPoints: number;

    preQuestionSelection: string;
    lessonSelection: ILessonPlayModel;

    //wrongCounter: number;
    //preQuestionAcknowledged: boolean;
}
