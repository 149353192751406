import * as data from './db.json';
import * as kate100 from './DiversityInHealthCare.json';
import * as demo101 from './WasteNotWantNot.json';

import { IStoryModel } from "../models";

export function dbScenarioForPlay(id: number): IStoryModel {
    if (id >= 1000) id = 3; // IGNORE RANDOM GENERATED DATA
    let story = data.stories.find(item => item.id === id);
    if (story === undefined && id == 100) story = kate100.stories.find(item => item.id === id);
    if (story === undefined && id == 101) story = demo101.stories.find(item => item.id === id);
    if (story) {
        return {
            ...story
        }
    } else {
        return null;
    }
}
