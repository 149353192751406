import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { IntroSideComponent } from './intro-side.component';
import { LessonCardComponentModule } from '../lesson-card/lesson-card.module';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, LessonCardComponentModule],
    declarations: [IntroSideComponent],
    exports: [IntroSideComponent]
})
export class IntroSideComponentModule { }
