import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { take } from 'rxjs/operators';
import { EGameStatus, EPlayMode } from '../../models';
import { getGame, getPlayMode } from '../selectors/play.selector';
import { Router } from '@angular/router';
import Paths from '../../paths';
import { environment } from 'src/environments/environment';
import { ModalController } from '@ionic/angular';
import { ModalMapPage } from '../../pages/modal-map/modal-map.page';
import { ModalHelpPage } from '../../pages/modal-help/modal-help.page';
import { setSidePaneEnabled, setSidePaneDisabled } from 'src/app/store/actions/play.actions';

@Injectable({
    providedIn: 'root',
})
export class NavService {

    constructor(private store$: Store<IAppState>,
        private router: Router,
        public modalController: ModalController,
    ) { }


    /* PRIVATE FUNCTIONS */

    private navigateTo(url: string, sidePane: boolean = undefined) {
        //console.log(`navigateTo(url: ${url}, sidePane: ${sidePane}) router.url=${this.router.url}`);
        if (url != this.router.url) {
            if (sidePane != undefined) {
                if (sidePane) {
                    this.enableSidePane();
                } else {
                    this.disableSidePane();
                }
            }
            this.router.navigate([url]);
        }
    }

    private async presentModalMap() {
        const modal = await this.modalController.create({
            component: ModalMapPage,
            componentProps: {
                'currentPage': this.router.url
            },
            swipeToClose: true,
            backdropDismiss: true,
            cssClass: 'my-map-class'
        });

        modal.onDidDismiss()
            .then((response) => {
                if (response.data != undefined) {
                    const page = response.data.page;
                    const index = response.data.index;
                    if (page != undefined) {
                        switch (page) {
                            case 'Intro':
                                this.Intro();
                                break;
                            case 'Scene':
                                this.Scene(index);
                                break;
                            case 'Bonus':
                                this.Bonus();
                                break;
                            default:
                        }
                    }
                }
            });

        return await modal.present();
    }

    async presentModalHelp() {
        const modal = await this.modalController.create({
            component: ModalHelpPage,
            swipeToClose: true,
            backdropDismiss: true,
            cssClass: 'my-custom-class'
        });
        return await modal.present();
    }

    private enableSidePane() {
        //console.log(`enableSidePane()`);
        this.store$.dispatch(setSidePaneEnabled());
    }

    private disableSidePane() {
        //console.log(`disableSidePane()`);
        this.store$.dispatch(setSidePaneDisabled());
    }


    /* PUBLIC FUNCTIONS */

    // Map
    public Map() {
        this.presentModalMap();
    }

    // Help
    public Help() {
        this.presentModalHelp();
    }

    // Start
    public Start() {
        //this.disableSidePane();
        this.navigateTo(Paths.start, false);
    }

    // Intro
    public Intro() {
        this.store$.select(getPlayMode).pipe(take(1)).subscribe(mode => {
            switch (mode) {
                case EPlayMode.SOLO:
                    this.navigateTo(Paths.intro, false);
                    break;
                case EPlayMode.FACILITATION:
                    this.navigateTo(Paths.facilitationIntro, true);
                    break;
                case EPlayMode.LEARNER:
                    this.navigateTo(Paths.facilitationIntro, false);
                    break;
                default:
            }
        })
    }

    // First Scene
    public FirstScene() {
        //this.enableSidePane();
        this.navigateTo(Paths.firstScene, true);
    }

    // Next Scene
    public NextScene() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            const available = game.scenes.filter(value => value.isVisible && !value.isSolved).length;
            if (game.gameStatus === EGameStatus.SCENE_RESULT) {
                // all solved go to results
                this.Success();
            } else {
                if (available > 1) {
                    // if more than one unlocked bring up map
                    this.presentModalMap();
                } else if (available == 1) {
                    // if only one unlocked go to it
                    const idx = game.scenes.findIndex(value => value.isVisible && !value.isSolved);
                    if (idx < game.selectedScene) {
                        // use map if only scene left is a previous one
                        this.presentModalMap();
                    } else {
                        this.Scene(idx);
                    }
                } else {
                    // if all locked go to next anyway
                    let next = game.selectedScene + 1;
                    if (next >= game.scenes.length) next = 0;
                    this.Scene(next);
                }
            }
        })
    }

    // Skip to Next Scene
    public SkipToNextScene() {
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            let next = game.selectedScene + 1;
            if (next >= game.scenes.length) next = 0;
            this.Scene(next);
        })
    }

    // Scene
    public Scene(index: number) {
        this.store$.select(getPlayMode).pipe(take(1)).subscribe(mode => {
            switch (mode) {
                case EPlayMode.SOLO:
                    this.navigateTo(Paths.getScene(index + 1), true);
                    break;
                case EPlayMode.FACILITATION:
                    this.navigateTo(Paths.getFacilitationScene(index + 1), true);
                    break;
                case EPlayMode.LEARNER:
                    this.navigateTo(Paths.getFacilitationScene(index + 1), true);
                    break;
                default:
            }
        })

    }

    // Failed Scenario
    public Failed() {
        //this.disableSidePane();
        this.navigateTo(Paths.failed, false);
    }

    // Bonus Round
    public Bonus() {
        //this.enableSidePane();
        this.navigateTo(Paths.bonus, true);
    }

    // Success Page
    public Success() {
        //this.disableSidePane();
        this.navigateTo(Paths.success, false);
    }

    // Scenario Complete
    public Complete() {
        //this.disableSidePane();
        this.navigateTo(Paths.complete, false);
    }

    // Dashboard
    public navigateToDashboard() {
        //console.log(`Navigate to ${environment.dashboardUrl}`);
        this.store$.select(getGame).pipe(take(1)).subscribe(game => {
            if (game.isDemo) {
                window.location.href = window.location.href;
            } else {
                window.location.href = environment.dashboardUrl;
            }
        })
    }

    /* FACILITATION FUNCTIONS */
    public FacilitationStart() {
        this.navigateTo(Paths.facilitationStart, false);
    }

    public AddTeams() {
        this.navigateTo(Paths.facilitationTeams, false);
    }

}