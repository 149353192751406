import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';

import { EGameStatus, ESceneStatus, EBonusStatus, ERankStatus, IStoryPlayModel, IScenePlayModel, IBonusPlayModel, ILessonPlayModel, IStoryModel, IAttachmentModel, EPlayMode } from '../../models';
import { SoloGameModel } from '../../typings/models/solo-game.model';

import { AlertController } from '@ionic/angular';
import { dbScenarioForPlay } from '../../data/db';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class PlayService {

    constructor(private store$: Store<IAppState>,
        public alertController: AlertController,
    ) { }

    /* GET DEMO GAME DETAILS */
    getDemoGame(storyId: number, playMode: EPlayMode): Observable<{ game: IStoryPlayModel }> {
        console.log("Loading Demo Game...");
        const story: IStoryModel = dbScenarioForPlay(storyId);
        const game: IStoryPlayModel = this.buildGameFromJSON(story, playMode);
        return of({ game });
    }

    /* BUILD GAME PLAY MODEL FROM A DEMO */
    private buildGameFromJSON(story: IStoryModel, playMode: EPlayMode): IStoryPlayModel {
        console.log(`buildGameFromJSON(${playMode})`);

        let scenes: IScenePlayModel[] = [];
        let lessons: ILessonPlayModel[] = [];
        let bonusQuestions: IBonusPlayModel[] = [];
        const totalScenes = story.scenes.filter(filter => !filter.isBogus).length;

        // scenes
        story.scenes.filter(filter => !filter.isBogus).map((scene, index) => {
            scenes.push({
                id: scene.id,
                shortTitle: `Scene ${index + 1}`,
                //title: `Scene ${index + 1}/${totalScenes}`,
                title: `Scene ${index + 1}`,
                description: scene.description,
                lessonPrompt: scene.lessonPrompt ? scene.lessonPrompt : story.lessonPrompt,
                keyword: scene.keyword,
                lesson: scene.lesson,
                reflection: scene.reflection,
                isCutScene: scene.isCutScene,
                isBogus: scene.isBogus,
                attachments: scene.attachments,
                reflectionAttachments: scene.reflectionAttachments,

                viewState: scene.preQuestion ? ESceneStatus.PREQUESTION : ESceneStatus.QUESTION,
                isVisible: index === 0,
                isSolved: false,
                points: 0,
                bonusPoints: 0,
                attempted: [],
                preQuestion: scene.preQuestion,
                preQuestionOptions: scene.preQuestionOptions,
                preQuestionCorrect: scene.preQuestionCorrect,
                preQuestionAnswer: undefined,
                preQuestionResponse: scene.preQuestionResponse,
                //preQuestionAcknowledged: scene.preQuestionCorrect == undefined,
                preQuestionSelection: undefined,
                lessonSelection: undefined,
                //wrongCounter: 0
            })
        });
        story.scenes.filter(filter => !filter.isCutScene).map((scene, index) => {
            lessons.push({
                id: scene.id,
                keyword: scene.keyword,
                lesson: scene.lesson,
                solved: false,
                points: 50
            })
        });

        // bonus questions
        story.bonuses.map((bonus, index) => {
            bonusQuestions.push({
                id: bonus.id,
                title: `Question ${index + 1}`,
                question: bonus.question,
                sceneOrLesson: bonus.sceneOrLesson,
                choices: bonus.choices,
                answerId: bonus.answerId,
                isBetAllowed: bonus.isBetAllowed,
                reasoning: bonus.reasoning,
                reasoningAttachments: bonus.reasoningAttachments,

                viewState: EBonusStatus.QUESTION,
                isVisible: index === 0,
                guess: undefined,
                selectedId: undefined,
                bet: 0,
                isSolved: false,
                bonusPoints: 0
            })
        });

        // scenario
        let attachments: IAttachmentModel[] = [];
        story.attachments.map(item => attachments.push(item));
        return {
            playMode: playMode,
            isDemo: true,
            id: story.id,
            title: story.title,
            imageUrl: story.imageUrl,
            storyTease: story.storyTease,
            introduction: story.introduction,
            lessonPrompt: story.lessonPrompt,
            storytellerName: story.storytellerName,
            storytellerTitle: story.storytellerTitle,
            storytellerMediaUrl: story.storytellerMediaUrl,
            storytellerBio: story.storytellerBio,
            allowableMoves: story.allowableMoves,
            scenes: scenes,
            lessons: shuffleLessons(lessons),
            bonusQuestions: bonusQuestions,
            attachments: attachments,
            rankLessons: story.rankLessons,
            points: 0,
            bonusPoints: 0,
            moves: story.allowableMoves,
            bonusDefaultAward: 10,
            gameStatus: EGameStatus.START,
            selectedScene: 0,
            selectedBonus: 0,
            historyId: undefined,
            rank1Status: ERankStatus.HIDE,
            rank2Status: ERankStatus.HIDE,
            rankSelection: 0
        }
    }
}

/* RANDOM SHUFFLE OF LESSONS FOR DISPLAY */
function shuffleLessons(lessons: ILessonPlayModel[]): ILessonPlayModel[] {
    let array = lessons;
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * i)
        const temp: ILessonPlayModel = array[i]
        array[i] = array[j]
        array[j] = temp
    }
    return array
}

/* CONVERT NULL STRING TO UNDEFINED */
function nullToUndefined(value: string): string {
    return value ? value : undefined;
}

/* CHECK IF STORY IS VALID */
export function isValidStory(story: SoloGameModel): boolean {
    return story !== undefined && story !== null && story.storyId > 0;
}

/* BUILD GAME PLAY MODEL FROM A STORY RETREIVED FROM API */

export function buildGameFromStory(story: SoloGameModel, playMode: EPlayMode): IStoryPlayModel {
    console.log(`buildGameFromStory(${playMode})`);

    if (!isValidStory(story)) return undefined;
    let scenes: IScenePlayModel[] = [];
    let lessons: ILessonPlayModel[] = [];
    let bonusQuestions: IBonusPlayModel[] = [];

    // scenes
    //const totalScenes = story.scenes.filter(filter => !filter.isBogus).length;
    story.scenes.filter(filter => !filter.isBogus).map((scene, index) => {

        /*
        let sceneAttachments: IAttachmentModel[] = scene.sceneAttachments.map(item => {
            console.log(item.contentType);
            return {
                ...item,
                url: environment.dashboardUrl.concat(item.url)
            }
        });
        */

        scenes.push({
            id: scene.sceneId,
            shortTitle: `Scene ${index + 1}`,
            //title: `Scene ${index + 1}/${totalScenes}`,
            title: `Scene ${index + 1}`,
            description: nullToUndefined(scene.description),
            lessonPrompt: scene.lessonPrompt ? scene.lessonPrompt : story.lessonPrompt,
            keyword: nullToUndefined(scene.keyword),
            lesson: nullToUndefined(scene.lesson),
            reflection: nullToUndefined(scene.reflection),
            isCutScene: scene.isCutScene,
            isBogus: scene.isBogus,
            attachments: scene.sceneAttachments,
            reflectionAttachments: scene.reflectionAttachments,
            preQuestion: nullToUndefined(scene.preQuestion),
            preQuestionOptions: scene.preQuestionOptions,
            preQuestionCorrect: nullToUndefined(scene.preQuestionAnswer),
            preQuestionAnswer: undefined,
            preQuestionResponse: scene.preQuestionResponse,
            //preQuestionAcknowledged: nullToUndefined(scene.preQuestionAnswer) == undefined,
            preQuestionSelection: undefined,
            lessonSelection: undefined,
            //wrongCounter: 0,

            viewState: nullToUndefined(scene.preQuestion) ? ESceneStatus.PREQUESTION : ESceneStatus.QUESTION,
            isVisible: index === 0,
            isSolved: false,
            points: 0,
            bonusPoints: 0,
            attempted: []
        })
    });
    story.scenes.filter(filter => !filter.isCutScene).map((scene, index) => {
        lessons.push({
            id: scene.sceneId,
            keyword: nullToUndefined(scene.keyword),
            lesson: nullToUndefined(scene.lesson),
            solved: false,
            points: 50
        })
    });

    // bonus questions
    story.bonuses.map((bonus, index) => {
        bonusQuestions.push({
            id: bonus.bonusId,
            title: `Question ${index + 1}`,
            question: nullToUndefined(bonus.question),
            sceneOrLesson: nullToUndefined(bonus.sceneOrLesson),
            choices: bonus.bonusChoices,
            answerId: bonus.correctSceneId,
            isBetAllowed: bonus.isBetAllowed,
            reasoning: nullToUndefined(bonus.reasoning),
            reasoningAttachments: bonus.reasoningAttachments,

            viewState: EBonusStatus.QUESTION,
            isVisible: index === 0,
            guess: undefined,
            selectedId: undefined,
            bet: 0,
            isSolved: false,
            bonusPoints: 0
        })
    });

    // scenario
    //let attachments: IAttachmentModel[] = [];
    //story.storyAttachments.map(item => attachments.push(item));

    //console.log(`story.storyImageUrl=${story.storyImageUrl}`);
    //console.log(`story.storyTellerImageUrl=${story.storyTellerImageUrl}`);

    return {
        playMode: playMode,
        isDemo: false,
        id: story.storyId,
        title: story.title,
        imageUrl: story.storyImageUrl,
        storyTease: nullToUndefined(story.storyTease),
        introduction: nullToUndefined(story.introduction),
        lessonPrompt: nullToUndefined(story.lessonPrompt),
        storytellerName: nullToUndefined(story.storyTellerName),
        storytellerTitle: nullToUndefined(story.storyTellerTitle),
        storytellerMediaUrl: story.storyTellerImageUrl ? story.storyTellerImageUrl : '/assets/icon/avatar.png',
        storytellerBio: nullToUndefined(story.storyTellerBio),
        allowableMoves: story.allowableMoves,
        scenes: scenes,
        lessons: shuffleLessons(lessons),
        bonusQuestions: bonusQuestions,
        attachments: story.storyAttachments,
        rankLessons: story.isRankQuestion,

        points: 0,
        bonusPoints: 0,
        moves: story.allowableMoves,
        bonusDefaultAward: 10,
        gameStatus: EGameStatus.START,
        selectedScene: 0,
        selectedBonus: 0,
        historyId: undefined,
        rank1Status: ERankStatus.HIDE,
        rank2Status: ERankStatus.HIDE,
        rankSelection: 0
    }
}