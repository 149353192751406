import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';

import { BehaviorSubject, Observable, of, from, defer, throwError } from 'rxjs';
import { catchError, filter, ignoreElements, map, shareReplay, take, tap } from 'rxjs/operators';

import { IStoryPlayModel } from '../../models';

import { getGame } from '../selectors/play.selector';
import { getFirstRank, getEndRank, getFullGameStats } from '../selectors/track.selector';
import { ITrackState } from '../state/track.state';

import { SoloGameModel } from '../../typings/models/solo-game.model';
import { StartSoloPlayHistoryModel } from '../../typings/models/start-solo-play-history.model';
import { EndSoloPlayHistoryModel } from '../../typings/models/end-solo-play-history.model';

import { UpdateSoloPlayBonusHistoryModel } from "../../typings/models/update-solo-play-bonus-history.model";
import { UpdateSoloPlayMoveHistoryModel } from "../../typings/models/update-solo-play-move-history.model";
import { UpdateSoloPlayPreQuestionHistoryModel } from "../../typings/models/update-solo-play-pre-question-history.model";
import { UpdateSoloPlayHistoryModel } from '../../typings/models/update-solo-play-history.model';

import { environment } from 'src/environments/environment';


interface ITokenResponse {
    access_token: string;
    refresh_token: string;
    id_token: string;
}

@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    private readonly apiServer = environment.apiUrl;
    private readonly cookieName = "CredsToken";
    private readonly accessTokenKey = 'access_token';


    constructor(
        private store$: Store<IAppState>,
        private http: HttpClient,
        private cookieService: CookieService,
    ) {

    }

    getAuthorizationToken(): string {
        //console.log(`Get cookie from ${this.cookieName}`);
        const cookie = this.cookieService.get(this.cookieName);
        if (cookie !== null && cookie !== undefined) {
            var jsonCookie = JSON.parse(cookie);
            const token = jsonCookie[this.accessTokenKey];
            //console.log(`Token found: ${token}`);
            return token;
        }
        return null;
    }

    getAuthorizationHeaders(): HttpHeaders {
        return new HttpHeaders({
            "Authorization": `Bearer ${this.getAuthorizationToken()}`
        })
    }


    getStoryAPI(storyId: number): Observable<SoloGameModel> {
        const url: string = `${this.apiServer}/sologame/getSoloGame`;
        console.log(`API: ${url}`);
        const options = {
            headers: this.getAuthorizationHeaders(),
            params: { "StoryId": storyId.toString() }
        }
        return this.http.get<SoloGameModel>(url, options);
    }


    postStartGameAPI(): Observable<number> {
        let game: IStoryPlayModel = undefined;
        this.store$.select(getGame).pipe(take(1)).subscribe(value => game = value);

        if (game === undefined) {
            throw new Error('No story found');
        }

        if (game.isDemo) {
            return of(0);
        }

        const url: string = `${this.apiServer}/sologame/startPlayHistory`;
        console.log(`API: ${url}`);

        const model: StartSoloPlayHistoryModel = {
            storyId: game.id,
            datePlayedOffset: new Date().getTimezoneOffset()
        }

        const options = {
            headers: this.getAuthorizationHeaders()
        }
        return this.http.post<number>(url, model, options);
    }

    postEndGameToAPI(passed: boolean): Observable<boolean> {
        let game: IStoryPlayModel = undefined;
        this.store$.select(getGame).pipe(take(1)).subscribe(value => game = value);

        if (game === undefined) {
            throw new Error('No story found');
        }

        if (game.isDemo) {
            return of(true);
        }

        const url: string = `${this.apiServer}/sologame/endPlayHistory`;
        console.log(`API: ${url}`);

        let startRank: number = undefined;
        let endRank: number = undefined;
        let bonusPoints: number = 0;
        let points: number = 0;
        const moves = game.allowableMoves - game.moves;

        if (passed) {
            this.store$.select(getFirstRank).pipe(take(1)).subscribe(value => startRank = value);
            this.store$.select(getEndRank).pipe(take(1)).subscribe(value => endRank = value);
            bonusPoints = game.bonusPoints;
            points = game.points;
        }

        const model: EndSoloPlayHistoryModel = {
            storyId: game.id,
            soloHistoryId: game.historyId,
            passed: passed,
            bonusPoints: bonusPoints,
            points: points,
            userMoves: moves,
            surveyStartSceneId: startRank,
            surveyEndSceneId: endRank
        }

        const options = {
            headers: this.getAuthorizationHeaders()
        }
        return this.http.post<boolean>(url, model, options);
    }


    postGameStatsToAPI(): Observable<boolean> {
        let game: IStoryPlayModel = undefined;
        this.store$.select(getGame).pipe(take(1)).subscribe(value => game = value);

        if (game === undefined) {
            throw new Error('No story found');
        }

        if (game.isDemo) {
            return of(true);
        }

        let tracking: ITrackState;
        this.store$.select(getFullGameStats).pipe(take(1)).subscribe(value => tracking = value);

        const url: string = `${this.apiServer}/sologame/updatePlayHistory`;
        console.log(`API: ${url}`);

        let points: number = 0;
        let bonusPoints: number = 0;
        let moves: number = 0;

        // pre questions
        const preQuestionHistory: UpdateSoloPlayPreQuestionHistoryModel[] = tracking.experience.map(item => {
            return {
                playSequence: item.seq,
                sceneId: item.sceneId,
                answer: item.answer,
                isCorrect: item.isCorrect,
                points: item.points
            }
        })

        // lesson question moves
        const moveHistory: UpdateSoloPlayMoveHistoryModel[] = tracking.moves.map(item => {
            moves++;
            points += item.points;
            return {
                playSequence: item.seq,
                sceneId: item.sceneId,
                answerSceneId: item.answerId,
                isCorrect: item.isCorrect,
                points: item.points,
                playTry: item.attempt
            }
        })

        // bonus questions
        const bonusHistory: UpdateSoloPlayBonusHistoryModel[] = tracking.bonus.map(item => {
            bonusPoints += item.points;
            return {
                playSequence: item.seq,
                bonusId: item.bonusId,
                answerSceneId: item.answerId,
                bet: item.bet,
                isCorrect: item.isCorrect,
                points: item.points
            }
        })

        // game history model
        const model: UpdateSoloPlayHistoryModel = {
            bonusPoints: bonusPoints,
            points: points,
            lastUpdatedUtc: new Date(Date.now()),
            userMoves: moves,
            soloHistoryId: game.historyId,
            preQuestionHistory: preQuestionHistory,
            moveHistory: moveHistory,
            bonusHistory: bonusHistory,
        }

        //console.log(model);

        const options = {
            headers: this.getAuthorizationHeaders()
        }
        return this.http.post<boolean>(url, model, options);
    }

}