import { IStoryPlayModel } from "../../models";

export interface IPlayState {
    game: IStoryPlayModel;
    loaded: boolean;
    loading: boolean;
    error: Error;
    gameStarted: boolean;
    isMenuShowing: boolean;
    sidePaneDisabled: boolean;
}

export const initialPlayState: IPlayState = {
    game: undefined,
    loaded: false,
    loading: false,
    error: null,
    gameStarted: false,
    isMenuShowing: false,
    sidePaneDisabled: true
}
