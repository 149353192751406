import { IStoryPlayModel } from './story-play-model';
import { ITeamPlayModel } from './team-play-model';
import { ITurnPlayModel } from './turn-play-model';

export enum EFacilitationStatus {
    NEW = "NEW",
    JOIN = "JOIN",
    WAIT = "WAIT",
    READY = "READY",
    VOTE1 = "VOTE1",
    RESULT_VOTE1_WRONG = "RESULT_VOTE1_WRONG",
    RESULT_VOTE1_CORRECT = "RESULT_VOTE1_CORRECT",
    RESULT_DEBATE = "RESULT_DEBATE",
    VOTE2 = "VOTE2",
    RESULT_FINAL = "RESULT_FINAL",
    COMPLETE = "COMPLETE"
}

export interface IFacilitationPlayModel {
    id: number;
    description: string;
    syncCode: string;
    status: EFacilitationStatus;
    teams: ITeamPlayModel[];
    turns: ITurnPlayModel[];
    currentSceneId: number;
    currentTurnIndex: number;
}
