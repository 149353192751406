import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { IMapStatus, getMapStatus } from '../../store/selectors/play.selector';
import { IMapItemState } from 'src/app/components/map-hex/map-hex.component';
import Paths from '../../paths';
import { EPlayMode } from 'src/app/models';

@Component({
  selector: 'app-modal-map',
  templateUrl: './modal-map.page.html',
  styleUrls: ['./modal-map.page.scss'],
})
export class ModalMapPage implements OnInit {
  @Input() currentPage: string;

  mapStatus = this.store$.pipe(select(getMapStatus));

  rows: number[] = [0, 1, 2, 3, 4, 5, 6];
  cells: number[][] = [
    [0, 0, 13, 15],
    [10, 12, 14, 16, 17],
    [9, 11, 2, 3, 18, 19],
    [8, 1, 0, 4, 20],
    [30, 7, 6, 5, 21, 22],
    [29, 28, 26, 24, 23],
    [0, 0, 27, 25]
  ];

  constructor(
    private store$: Store<IAppState>,
    public modalCtrl: ModalController
  ) { }

  closeModal(page: string = "", index: number = 0) {
    this.modalCtrl.dismiss({
      'dismissed': true,
      'page': page,
      'index': index
    });
  }

  cellState(cell: number, status: IMapStatus): IMapItemState {
    //console.log(`currentPage=${this.currentPage}`);

    // hidden
    if (cell == 0) return undefined;

    // intro
    if (cell == 1) {
      const isCurrent = this.currentPage == Paths.intro;
      return {
        isActive: true,
        isCurrent,
        title: 'Intro',
        locked: false,
        display: isCurrent ? 'current' : 'solved'
      }
    }

    // scene
    if (cell >= 2 && cell < (2 + status.sceneCount)) {
      const sceneIdx = cell - 2;
      const scene = status.scenes[sceneIdx];
      //const isCurrent = status.isSceneCurrent && sceneIdx == status.selectedSceneIdx;
      const isCurrent = this.currentPage == Paths.getScene(sceneIdx + 1);
      const locked = status.isIntroCurrent || !scene.isVisible;
      const complete = scene.isSolved;
      return {
        isActive: true,
        isCurrent,
        title: (sceneIdx + 1).toString(),
        sceneIdx,
        scene,
        locked,
        complete,
        display: isCurrent ? 'current' : locked ? 'locked' : complete ? 'solved' : 'active'
      }
    }

    // bonus
    if (status.playMode == EPlayMode.SOLO) {
      const bonusCell = status.sceneCount + 2;
      if (status.bonusCount > 0 && cell == bonusCell) {
        const isCurrent = this.currentPage == Paths.bonus;
        const locked = status.isIntroCurrent || status.isSceneCurrent;
        return {
          isActive: true,
          isCurrent,
          title: "Bonus",
          locked,
          display: isCurrent ? 'current' : locked ? 'locked' : 'active'
        }
      }
    }

    // inactive
    return {
      isActive: false,
      isCurrent: false,
      display: "inactive"
    }
  }

  introClicked(state: IMapItemState) {
    if (state.title == "Intro" && !state.locked) {
      this.closeModal('Intro');
    }
  }

  sceneClicked(state: IMapItemState) {
    if (state.sceneIdx != undefined) {
      if (state.scene.isVisible && !state.locked) {
        this.closeModal('Scene', state.sceneIdx);
      }
    }
  }

  bonusClicked(state: IMapItemState) {
    if (state.title == "Bonus" && !state.locked) {
      this.closeModal('Bonus');
    }
  }

  ngOnInit() {
  }

}
