import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { getLeaderBoard, getIsStateVote, getCurrentTurn } from '../../store/selectors/facilitation.selector';
import { ILearnerModel, ITeamPlayModel, IVotePlayModel } from 'src/app/models';
import { FacilitationService } from 'src/app/store/service/facilitation.service';

@Component({
  selector: 'app-leader-board',
  templateUrl: './leader-board.component.html',
  styleUrls: ['./leader-board.component.scss'],
})
export class LeaderBoardComponent implements OnInit {
  @Input() page: string;

  leaderBoard = this.store$.pipe(select(getLeaderBoard));
  isVote = this.store$.pipe(select(getIsStateVote));
  //isResult = this.store$.pipe(select(getIsStateResult));
  currentTurn = this.store$.pipe(select(getCurrentTurn));

  showTeamDetails: boolean = false;

  constructor(private store$: Store<IAppState>,
    private facilitationService: FacilitationService) { }

  toggleTeamDetails() {
    this.showTeamDetails = !this.showTeamDetails;
  }

  onAddPoints(team: ITeamPlayModel) {
    this.facilitationService.addPoints(team);
  }

  onRemovePoints(team: ITeamPlayModel) {
    this.facilitationService.removePoints(team);
  }

  onTriggerVote(learner: ILearnerModel) {
    this.facilitationService.addLearnerVote(learner);
  }

  makeUpVotes(allCorrect: boolean = false) {
    this.facilitationService.makeUpVotes(allCorrect);
  }

  ngOnInit() { }

}
