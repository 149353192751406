import { SafeResourceUrl } from '@angular/platform-browser';

export class IAttachmentModel {
    id?: number;
    contentType?: string;
    fileExtension?: string;
    fileName?: string;
    fileSize?: number;
    url: string;
    safeUrl?: SafeResourceUrl;
}

