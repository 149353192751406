import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IScenePlayModel } from 'src/app/models';

export class IMapItemState {
  isActive: boolean;
  isCurrent: boolean;
  title?: string;
  sceneIdx?: number;
  scene?: IScenePlayModel;
  locked?: boolean;
  complete?: boolean;
  display: string;
}

@Component({
  selector: 'app-map-hex',
  templateUrl: './map-hex.component.html',
  styleUrls: ['./map-hex.component.scss'],
})
export class MapHexComponent implements OnInit {
  @Input() isHidden: boolean = false;
  @Input() state: IMapItemState;
  @Output() onIntroClicked = new EventEmitter<IMapItemState>();
  @Output() onSceneClicked = new EventEmitter<IMapItemState>();
  @Output() onBonusClicked = new EventEmitter<IMapItemState>();

  constructor() { }

  hexClicked(state: IMapItemState) {
    // intro selected
    if (state.title == "Intro") {
      this.onIntroClicked.emit(state);
    }

    // scene selected
    if (state.sceneIdx != undefined) {
      this.onSceneClicked.emit(state);
    }

    // bonus selected
    if (state.title == "Bonus") {
      this.onBonusClicked.emit(state);
    }
  }

  ngOnInit() { }

}
