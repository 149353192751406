import { createAction, props } from "@ngrx/store";
import { EPlayMode } from "src/app/models";

export const getStoryFromServer = createAction(
    '[Security] Get Story From Server',
    props<{ storyId: number, playMode: EPlayMode }>()
)

export const postStartGameToServer = createAction(
    '[Security] Post Start Game To Server',
)

export const postEndGameToServer = createAction(
    '[Security] Post End Game To Server',
    props<{ passed: boolean }>()
)

export const postGameStatsToServer = createAction(
    '[Security] Post Game Stats To Server'
)
