import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { getGameTitle, getTotalPoints, getMoves, getPercentMovesLeft } from '../../store/selectors/play.selector';
import { NavService } from 'src/app/store/service/nav.service';
import { FacilitationService } from 'src/app/store/service/facilitation.service';
import { LearnerService } from 'src/app/store/service/learner.service';

@Component({
  selector: 'app-game-header',
  templateUrl: './game-header.component.html',
  styleUrls: ['./game-header.component.scss'],
})
export class GameHeaderComponent implements OnInit {
  @Input() page: string = "";
  @Input() shiftScore: boolean = true;
  @Output() onHelpLink = new EventEmitter();
  @Output() onMapLink = new EventEmitter();

  gameTitle = this.store$.pipe(select(getGameTitle));
  gamePoints = this.store$.pipe(select(getTotalPoints));
  gameMoves = this.store$.pipe(select(getMoves));
  percentMovesLeft = this.store$.pipe(select(getPercentMovesLeft));

  //testPoints = "10";

  constructor(private store$: Store<IAppState>,
    private navService: NavService,
    private facilitationService: FacilitationService,
    private learnerService: LearnerService,
  ) { }

  isSoloStartPage(page: string) {
    if (page == 'start' || page == 'launch' || page == 'play' || page == '') return true;
    return false;
  }

  isFacilitationStartPage(page: string) {
    if (page == 'facilitation-start' || page == 'teach') return true;
    return false;
  }

  isLearnerStartPage(page: string) {
    if (page == 'learner-start' || page == 'learn') return true;
    return false;
  }

  showDashboardLink() {
    if (this.isSoloStartPage(this.page)) return true;
    return false;
  }

  showHelpLink() {
    if (this.isSoloStartPage(this.page)) return true;
    return false;
  }

  showMapLink() {
    if (this.page == 'intro' || this.page == 'scene' || this.page == 'bonus') return true;
    if (this.page == 'facilitation-intro' || this.page == 'facilitation-scene') return true;
    return false;
  }

  showScore() {
    if (this.isSoloStartPage(this.page)) return false;
    if (this.page.startsWith('facilitation')) return false;
    if (this.page.startsWith('learner')) return false;
    return true;
  }

  showMoves() {
    if (this.page == 'intro' || this.page == 'scene' || this.page == 'success') return true;
    return false;
  }

  showLogo() {
    if (this.isSoloStartPage(this.page)) return true;
    //if (this.isFacilitationStartPage(this.page)) return true;
    if (this.page.startsWith('facilitation')) return true;
    if (this.isLearnerStartPage(this.page)) return true;
    return false;
  }

  showFacilitatorRefresh() {
    if (this.page == 'facilitation-teams') return true;
    return false;
  }

  onDashboardClicked() {
    this.navService.navigateToDashboard();
  }

  onFacilitatorRefresh() {
    this.facilitationService.checkStatus();
  }

  showLearnerRefresh() {
    if (this.isLearnerStartPage(this.page)) return true;
    return false;
  }

  onLearnerRefresh() {
    this.learnerService.checkStatus();
  }

  helpLinkClicked() {
    this.onHelpLink.emit();
  }

  mapLinkClicked() {
    this.onMapLink.emit();
  }

  ngOnInit() { }

}
