import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of, EMPTY } from 'rxjs';
import { map, switchMap, mergeMap, catchError, withLatestFrom, tap, concatMap, delay } from 'rxjs/operators';
import { FacilitationActions } from "../actions";
import { FacilitationService } from '../service/facilitation.service';
import { NavService } from '../service/nav.service';

@Injectable({
    providedIn: 'root',
})
export class FacilitationEffects {

    constructor(private actions$: Actions,
        private facilitationService: FacilitationService,
        private navService: NavService,
    ) { }

    facilitationLoadRequired$ = createEffect(
        () => this.actions$.pipe(
            ofType(FacilitationActions.facilitationLoadRequired),
            tap(() => this.navService.FacilitationStart())
        ),
        { dispatch: false }
    );
}