import { Action, createReducer, on } from '@ngrx/store';

import { ISecurityState, initialSecurityState } from '../state/security.state';
import { SecurityActions } from "../actions";

const reducer = createReducer(
    initialSecurityState,
    // signInSuccess
    /*
    on(SecurityActions.signInSuccess, (state) => ({
        ...state,
        signedIn: true
    })),
    */
);

export function securityReducer(state: ISecurityState, action: Action) {
    return reducer(state, action);
}
