import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { forkJoin, of } from 'rxjs';
import { map, switchMap, mergeMap, catchError, withLatestFrom, filter, tap, concatMap } from 'rxjs/operators';
import { SecurityActions, PlayActions } from "../actions";
import { SecurityService } from '../service/security.service';
//import { PlayService } from '../service/play.service';
//import { Store, select } from '@ngrx/store';
//import { IAppState } from '../state/app.state';


@Injectable({
    providedIn: 'root',
})
export class SecurityEffects {

    constructor(private actions$: Actions,
        //private store$: Store<IAppState>,
        private securityService: SecurityService,
        //private playService: PlayService,
        //private router: Router
    ) { }

    getStoryFromServer$ = createEffect(
        () => this.actions$.pipe(
            ofType(SecurityActions.getStoryFromServer),
            concatMap((action) =>
                this.securityService.getStoryAPI(action.storyId).pipe(
                    map((story) =>
                        PlayActions.loadGameFromStory({ story, playMode: action.playMode })
                    ),
                    catchError(error => of(
                        PlayActions.loadGameFailure({ error })
                    ))
                )
            )
        )
    );

    postStartGameToServer$ = createEffect(
        () => this.actions$.pipe(
            ofType(SecurityActions.postStartGameToServer),
            concatMap(() =>
                this.securityService.postStartGameAPI().pipe(
                    map((historyId) =>
                        PlayActions.startGame({ historyId })
                    )
                )
            )
        )
    );

    postEndGameToServer$ = createEffect(
        () => this.actions$.pipe(
            ofType(SecurityActions.postEndGameToServer),
            concatMap(action =>
                this.securityService.postEndGameToAPI(action.passed).pipe(
                )
            )
        ),
        { dispatch: false }
    );

    /*
    postGameStatsToServer$ = createEffect(
        () => this.actions$.pipe(
            ofType(SecurityActions.postGameStatsToServer),
            switchMap(action =>
                this.securityService.postGameStatsToAPI().pipe(
                )
            )
        ),
        { dispatch: false }
    );
    */

    /*
    postEndGameToServer$ = createEffect(
        () => this.actions$.pipe(
            ofType(SecurityActions.postEndGameToServer),
            concatMap(action =>
                forkJoin(
                    [
                        this.securityService.postGameStatsToAPI().pipe(),
                        this.securityService.postEndGameToAPI(action.passed).pipe()
                    ]
                )
            )
        ),
        { dispatch: false }
    );
    */

    postGameStatsToServer$ = createEffect(
        () => this.actions$.pipe(
            ofType(SecurityActions.postGameStatsToServer),
            concatMap(() =>
                this.securityService.postGameStatsToAPI().pipe(
                )
            )
        ),
        { dispatch: false }
    );

}