export default class Paths {
    static readonly start = '/start';

    static readonly intro = '/intro';

    static readonly firstScene = '/scene/1';

    static readonly failed = '/fail';

    static readonly success = '/success';

    static readonly bonus = '/bonus';

    static readonly complete = '/complete';

    static getScene = (sceneIndex: number) => `/scene/${sceneIndex}`;


    /* Facilitation Specific */

    static readonly facilitationStart = '/facilitation-start';

    static readonly facilitationTeams = '/facilitation-teams';

    static readonly facilitationIntro = '/facilitation-intro';

    static getFacilitationScene = (sceneIndex: number) => `/facilitation-scene/${sceneIndex}`;

    /* Facilitated Learner Specific */

    static readonly learnerStart = '/learner-start';


}
