import { createAction, props } from "@ngrx/store";

//import { ITrackMoveModel, ITrackBonusModel, ITrackExperienceModel } from "../../models";

export const saveMove = createAction(
    '[Track] Track Move',
    props<{
        sceneId: number,
        answerId: number,
        isCorrect: boolean,
        points: number,
        attempt: number
    }>()
)

export const saveBonus = createAction(
    '[Track] Track Bonus',
    props<{
        bonusId: number,
        answerId: number,
        isCorrect: boolean,
        bet: number,
        points: number
    }>()
)

export const saveExperience = createAction(
    '[Track] Track Experience',
    props<{
        sceneId: number,
        answer: string,
        isCorrect: boolean,
        points: number
    }>()
)

export const saveRankBeginning = createAction(
    '[Track] Save Rank Beginning',
    props<{ rank: number }>()
)

export const saveRankEnd = createAction(
    '[Track] Save Rank End',
    props<{ rank: number }>()
)
