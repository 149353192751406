import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { getFirstRankQuestion, getSelectedSceneQuestion, getSecondRankQuestion, getSelectedBonusQuestion, getMoves, getPercentMovesLeft } from '../../store/selectors/play.selector';
import { GameService } from '../../store/service/game.service';
import { NavService } from '../../store/service/nav.service';

@Component({
  selector: 'app-side-button',
  templateUrl: './side-button.component.html',
  styleUrls: ['./side-button.component.scss'],
})
export class SideButtonComponent implements OnInit {
  @Input() page: string;

  firstRankQuestion = this.store$.pipe(select(getFirstRankQuestion));
  sceneQuestion = this.store$.pipe(select(getSelectedSceneQuestion));
  secondRankQuestion = this.store$.pipe(select(getSecondRankQuestion));
  bonusQuestion = this.store$.pipe(select(getSelectedBonusQuestion));
  gameMoves = this.store$.pipe(select(getMoves));
  percentMovesLeft = this.store$.pipe(select(getPercentMovesLeft));

  constructor(
    private store$: Store<IAppState>,
    private gameService: GameService,
    private navService: NavService
  ) { }

  /* START PAGE */
  onStartGame() {
    this.gameService.startGame();
  }

  /* INTRO PAGE */
  onStartQuestions() {
    this.gameService.advanceIntro();
  }

  firstRankQuestionSubmit() {
    this.gameService.submitFirstRank();
  }

  /*
  firstRankQuestionContinue() {
    this.gameService.advanceIntro();
  }
  */

  /* SCENE PAGE */
  scenePreQuestionSubmit() {
    this.gameService.submitPreQuestion();
  }

  scenePreQuestionResult() {
    this.gameService.acknowledgePreQuestion();
  }

  sceneQuestionSubmit() {
    this.gameService.submitLesson();
  }

  sceneQuestionWrongAgain() {
    this.gameService.replayScene();
  }

  sceneQuestionWrongNext() {
    this.gameService.nextScene();
  }

  sceneQuestionCorrect() {
    this.gameService.nextScene();
  }

  /* SUCCESS PAGE */
  onAdvanceSuccess() {
    this.gameService.advanceSuccess();
  }

  secondRankQuestionSubmit() {
    this.gameService.submitSecondRank();
  }

  secondRankQuestionContinue() {
    this.gameService.advanceSuccess();
  }

  /* BONUS PAGE */
  bonusQuestionSubmit() {
    this.gameService.submitBonusQuestion();
  }

  bonusBetCancel() {
    this.gameService.betCancel();
  }

  bonusBetSubmit() {
    this.gameService.betBonus();
  }

  bonusQuestionContinue() {
    this.gameService.advanceBonus();
  }

  /* COMPLETE PAGE */
  onClickDashboardButton() {
    this.navService.navigateToDashboard();
  }


  ngOnInit() { }

}
