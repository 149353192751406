import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of, EMPTY } from 'rxjs';
import { map, switchMap, mergeMap, catchError, withLatestFrom, tap, concatMap, delay } from 'rxjs/operators';
import { PlayActions } from "../actions";
import { PlayService } from '../service/play.service';
//import { SecurityService } from '../service/security.service';
import { NavService } from '../service/nav.service';
//import { Router } from '@angular/router';
//import { dispatch } from 'rxjs/internal/observable/pairs';

@Injectable({
    providedIn: 'root',
})
export class PlayEffects {

    constructor(private actions$: Actions,
        //private router: Router,
        private playService: PlayService,
        private navService: NavService,
    ) { }


    loadDemoGame$ = createEffect(
        () => this.actions$.pipe(
            ofType(PlayActions.loadDemoGame),
            concatMap((action) =>
                this.playService.getDemoGame(action.storyId, action.playMode).pipe(
                    map(({ game }) =>
                        PlayActions.loadGameSuccess({ game })
                    ),
                    catchError(error => of(
                        PlayActions.loadGameFailure({ error })
                    ))
                )
            )
        )
    );

    gameLoadRequired$ = createEffect(
        () => this.actions$.pipe(
            ofType(PlayActions.gameLoadRequired),
            //tap(() => this.router.navigate(['/start']))
            tap(() => this.navService.Start())
        ),
        { dispatch: false }
    );

    gameStartRequired$ = createEffect(
        () => this.actions$.pipe(
            ofType(PlayActions.gameStartRequired),
            //tap(() => this.router.navigate(['/start']))
            tap(() => this.navService.Start())
        ),
        { dispatch: false }
    );

}