import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EFacilitationStatus, ILearnerModel, ITeamPlayModel } from 'src/app/models';
import { ICurrentTurn } from 'src/app/store/selectors/facilitation.selector';

@Component({
  selector: 'app-team-score',
  templateUrl: './team-score.component.html',
  styleUrls: ['./team-score.component.scss'],
})
export class TeamScoreComponent implements OnInit {
  @Input() team: ITeamPlayModel;
  @Input() currentTurn: ICurrentTurn;
  @Input() showTeamDetails: boolean;

  //@Input() voteStatus: string = "";

  @Output() addPoints = new EventEmitter<ITeamPlayModel>();
  @Output() removePoints = new EventEmitter<ITeamPlayModel>();
  @Output() triggerVote = new EventEmitter<ILearnerModel>();

  //showTeamDetails: boolean = false;

  constructor() { }

  /*
  toggleTeamDetails() {
    this.showTeamDetails = !this.showTeamDetails;
  }
  */

  /*
    showLearnerVoted(learner: ILearnerModel): boolean {
      if (this.currentTurn.status != EFacilitationStatus.VOTE1) return false;
      return learner.firstVoteId != undefined;
    }
    */

  /*
showFirstVote(): boolean {
  return this.currentTurn.status == EFacilitationStatus.RESULT_VOTE1_CORRECT ||
    this.currentTurn.status == EFacilitationStatus.RESULT_VOTE1_WRONG ||
    this.currentTurn.status == EFacilitationStatus.RESULT_DEBATE;
}
*/

  /*
  showSecondVote(): boolean {
    return this.currentTurn.status == EFacilitationStatus.VOTE2;
  }
  */

  /*
    learnerCurrentVote(learner: ILearnerModel): string {
      return learner.firstVoteString;
    }
  */

  /*  
  learnerVoteStatus(learner: ILearnerModel): string {
    if (this.currentTurn == undefined) return "";
    if (this.currentTurn.turn == undefined) return "";
    if (this.currentTurn.turn.votes == undefined) return "";

    //console.log(this.currentTurn.turn);
    //console.log(`learnerVoteStatus: learner=${learner.initials}`);
    if (this.currentTurn.turn.votes.find(vote => vote.learnerId == learner.id)) return "Yes";
    return "";
  }
*/


  onAddPoints(team: ITeamPlayModel) {
    this.addPoints.emit(team);
  }

  onRemovePoints(team: ITeamPlayModel) {
    this.removePoints.emit(team);
  }

  onTriggerVote(learner: ILearnerModel) {
    this.triggerVote.emit(learner);
  }

  ngOnInit() { }

}
