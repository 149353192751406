export interface ISecurityState {
    refreshToken: string | null;
    roles: string[] | null;
    name: string | null;
    signedIn: boolean;
    userId: number | null;
}

export const initialSecurityState: ISecurityState = {
    refreshToken: null,
    roles: null,
    name: null,
    signedIn: false,
    userId: null
}
