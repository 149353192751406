import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-solo-score',
  templateUrl: './solo-score.component.html',
  styleUrls: ['./solo-score.component.scss'],
})
export class SoloScoreComponent implements OnInit {
  @Input() moves: number;
  @Input() points: number;
  @Input() percent: number;
  @Input() moveWarning: boolean = false;

  constructor() { }

  ngOnInit() { }

}
