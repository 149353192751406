import { ActionReducerMap } from '@ngrx/store';
import { IAppState } from '../state/app.state';

import { securityReducer } from '../reducers/security.reducer';
import { playReducer } from '../reducers/play.reducer';
import { trackReducer } from '../reducers/track.reducer';
import { facilitationReducer } from '../reducers/facilitation.reducer';

export const appReducers: ActionReducerMap<IAppState, any> = {
    security: securityReducer,
    play: playReducer,
    track: trackReducer,
    facilitation: facilitationReducer
}

