import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { IAppState } from './store/state/app.state';
import { setMenuShowing } from 'src/app/store/actions/play.actions';
import { getIsContentShift, getSidePaneDisabled } from 'src/app/store/selectors/play.selector';
import { NavService } from 'src/app/store/service/nav.service';

const SPLIT_BREAK_POINT = 992;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  public selectedPage = '';

  sidePaneDisabled = this.store$.pipe(select(getSidePaneDisabled));
  contentShift = this.store$.pipe(select(getIsContentShift));

  // Side Menu Button
  showMenuButton1: boolean = false;
  menuButton1: string = "Submit Answer";
  menuButton1Disabled: boolean = true;
  showMenuButton2: boolean = false;
  menuButton2: string = "Next";
  menuButton2Disabled: boolean = false;

  deviceWidth: number = window.innerWidth;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private store$: Store<IAppState>,
    private router: Router,
    private navService: NavService
  ) {
    this.initializeApp();
  }

  sideMenuWillOpen(e: object) {
    this.store$.dispatch(setMenuShowing({ showing: true }));
  }

  sideMenuDidClose(e: object) {
    this.store$.dispatch(setMenuShowing({ showing: false }));
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        const splitUrl = event.url.split('/');
        var page = "start";
        if (splitUrl.length > 1) {
          page = splitUrl[1];
        }
        if (page == undefined || page == '') page = "start";
        //console.log(`selectedPage=${page}`);
        this.selectedPage = page;

        //if (page == 'intro') this.disableSidePane = false;
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });

    // check if side menu showing
    this.store$.dispatch(setMenuShowing({ showing: window.innerWidth >= SPLIT_BREAK_POINT }));
    window.onresize = (e: any) => {
      if (window.innerWidth >= SPLIT_BREAK_POINT && this.deviceWidth < SPLIT_BREAK_POINT) {
        this.store$.dispatch(setMenuShowing({ showing: true }));
      } else if (window.innerWidth < SPLIT_BREAK_POINT && this.deviceWidth >= SPLIT_BREAK_POINT) {
        this.store$.dispatch(setMenuShowing({ showing: false }));
      }
      this.deviceWidth = window.innerWidth;
    }
  }

  showLeaderBoard() {
    if (this.selectedPage == 'facilitation-intro' || this.selectedPage == 'facilitation-scene') return true;
    return false;
  }

  onHelpLinkClicked() {
    this.navService.Help();
  }

  onMapLinkClicked() {
    this.navService.Map();
  }

  ngOnInit() {
  }
}
