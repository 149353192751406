import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { getSelectedSceneQuestion, getRemainingLessons, getTotalPoints, getMoves, getPercentMovesLeft } from '../../store/selectors/play.selector';
import { ILessonPlayModel } from '../../models/lesson-play-model';
import { setSceneLessonSelection, setSceneOptionSelection } from '../../store/actions/play.actions';

@Component({
  selector: 'app-scene-side',
  templateUrl: './scene-side.component.html',
  styleUrls: ['./scene-side.component.scss'],
})
export class SceneSideComponent implements OnInit {
  scene = this.store$.pipe(select(getSelectedSceneQuestion));
  lessons = this.store$.pipe(select(getRemainingLessons));
  gamePoints = this.store$.pipe(select(getTotalPoints));
  gameMoves = this.store$.pipe(select(getMoves));
  percentMovesLeft = this.store$.pipe(select(getPercentMovesLeft));

  constructor(private store$: Store<IAppState>) {
  }

  onOptionSelectionChange(option: string) {
    this.store$.dispatch(setSceneOptionSelection({ option }));
  }

  onSelectionChange(lesson: ILessonPlayModel) {
    this.store$.dispatch(setSceneLessonSelection({ lesson }));
  }

  ngOnInit() {
  }

}
