import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-page-button',
  templateUrl: './page-button.component.html',
  styleUrls: ['./page-button.component.scss'],
})
export class PageButtonComponent implements OnInit {
  @Input() text: string;
  @Input() isVisible: boolean = true;
  @Input() isDisabled: boolean = false;
  @Input() isShift: boolean = false;
  @Output() onClicked = new EventEmitter();

  constructor() { }

  buttonClicked() {
    this.onClicked.emit();
  }

  ngOnInit() { }

}
