import { SecurityEffects } from './security.effects';
import { PlayEffects } from './play.effects';
import { FacilitationEffects } from './facilitation.effects';

export const appEffects = [
    SecurityEffects,
    PlayEffects,
    FacilitationEffects
]

