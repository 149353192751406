import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ILessonPlayModel } from '../../models/lesson-play-model';

@Component({
  selector: 'app-lesson-card',
  templateUrl: './lesson-card.component.html',
  styleUrls: ['./lesson-card.component.scss'],
})
export class LessonCardComponent implements OnInit {
  @Input() lesson: ILessonPlayModel = undefined;
  @Input() disabled: boolean = true;
  @Input() showPoints: boolean = true;
  @Input() highLight: boolean = false;
  @Output() selected = new EventEmitter<ILessonPlayModel>();

  constructor() { }

  onSelected(lesson: ILessonPlayModel) {
    this.selected.emit(lesson)
  }

  ngOnInit() { }

}
