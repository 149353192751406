import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { getSelectedBonusQuestion } from '../../store/selectors/play.selector';
import { GameService } from '../../store/service/game.service';
import { setBonusSelection, setBonusBet } from '../../store/actions/play.actions';
import { isNumber } from 'util';

@Component({
  selector: 'app-bonus-side',
  templateUrl: './bonus-side.component.html',
  styleUrls: ['./bonus-side.component.scss'],
})
export class BonusSideComponent implements OnInit {
  question = this.store$.pipe(select(getSelectedBonusQuestion));

  constructor(
    private store$: Store<IAppState>,
    private gameService: GameService
  ) { }

  onSelectionChange(sceneId: number) {
    if (sceneId > 0) {
      this.store$.dispatch(setBonusSelection({ sceneId }));
    }
  }

  betAmountChanged(event: any) {
    const bet = event.target.value;
    if (typeof bet === 'number' && bet >= 0) {
      this.store$.dispatch(setBonusBet({ bet }));
    }
  }

  ngOnInit() { }

}
