import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { take } from 'rxjs/operators';
import { EFacilitationStatus } from '../../models';
import { getClassroom } from '../selectors/facilitation.selector';
import { NavService } from './nav.service';
import { addTeam, setStatus, setTeams } from '../actions/facilitation.actions';
import { ITeamPlayModel, IFacilitationPlayModel } from '../../models';

@Injectable({
    providedIn: 'root',
})
export class LearnerService {

    constructor(private store$: Store<IAppState>,
        //private menu: MenuController,
        private navService: NavService
        //private securityService: SecurityService
    ) { }

    /*
    private _DB_Get_Teams(): ITeamPlayModel[] {
        const data: IFacilitationPlayModel = JSON.parse(localStorage.getItem('Facilitation'));
        if (data) {
            return data.teams;
        }
        return undefined;
    }
    */

    public checkStatus() {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            const data: IFacilitationPlayModel = JSON.parse(localStorage.getItem('Facilitation'));
            console.log(data);
            if (data) {
                switch (data.status) {
                    case EFacilitationStatus.NEW:
                        break;
                    case EFacilitationStatus.JOIN:
                        if (classroom.status === EFacilitationStatus.NEW) {
                            this.store$.dispatch(setTeams({ teams: data.teams }));
                            this.store$.dispatch(setStatus({ status: EFacilitationStatus.JOIN }));
                        }
                        break;
                    case EFacilitationStatus.READY:
                        this.store$.dispatch(setStatus({ status: EFacilitationStatus.READY }));
                        break;
                    default:
                }
            }
        })
    }

    /*
    public retrieveTeamNames() {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            if (classroom.status === EFacilitationStatus.JOIN || classroom.status === EFacilitationStatus.JOIN) {
                // TESTING: with localStorage, add API to get teams from database
                const data = JSON.parse(localStorage.getItem('teams'));
                if (data) {
                    const teams: ITeamPlayModel[] = data;
                    this.store$.dispatch(setTeams({ teams }));
                }
            }
        })
    }
    */

    public joinClass(team: ITeamPlayModel) {
        this.store$.select(getClassroom).pipe(take(1)).subscribe(classroom => {
            if (classroom.status === EFacilitationStatus.JOIN) {

                // TESTING: with localStorage, add API and websocket to send learner details and team back
                const data = {
                    teamId: team.id,
                    name: "Jesse Brown",
                    userId: 1
                }
                localStorage.setItem('learner', JSON.stringify(data));

                this.store$.dispatch(setStatus({ status: EFacilitationStatus.WAIT }));
            }
        })
    }

}