import { createSelector, State } from '@ngrx/store';

import { IAppState } from '../state/app.state';
import { IFacilitationState } from '../state/facilitation.state';
import { IPlayState } from '../state/play.state';
import { EFacilitationStatus, ILessonPlayModel, IScenePlayModel, ITeamPlayModel /*, ITurnPlayModel */ } from 'src/app/models';
import { LessonCardComponent } from 'src/app/components/lesson-card/lesson-card.component';

const selectFacilitation = (state: IAppState) => state.facilitation;
const selectPlay = (state: IAppState) => state.play;

// facilitation status
export const getClassroom = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom);

export const getIsStateNew = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom.status == EFacilitationStatus.NEW);
export const getIsStateJoin = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom.status == EFacilitationStatus.JOIN);
export const getIsStateWait = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom.status == EFacilitationStatus.WAIT);
export const getIsStateReady = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom.status == EFacilitationStatus.READY);
export const getIsStateVote = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom.status == EFacilitationStatus.VOTE1 || state.classroom.status == EFacilitationStatus.VOTE2);

// Join
export const getTeams = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom.teams);
export const getCode = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom.syncCode);
export const getTotalLearners = createSelector(selectFacilitation, (state: IFacilitationState) => state.classroom.teams.reduce((total, team) => {
    return total + team.learners.length;
}, 0));

// Leaderboard
export const getLeaderBoard = createSelector(selectFacilitation, (state: IFacilitationState) => {
    return state.classroom.teams.map(item => item).sort((a, b) => {
        if (b.teamScore > a.teamScore) return 1;
        if (b.teamScore < a.teamScore) return -1;
        if (a.teamName > b.teamName) return 1;
        if (a.teamName < b.teamName) return -1;
    });
});

// Scene
export class ICurrentFacilitationScene {
    scene: IScenePlayModel;
    status: EFacilitationStatus;
    isVoteAvailable: boolean;
    isVoteInProgress: boolean;
    showVote1Wrong: boolean;
    showVote1Correct: boolean;
    showDebate: boolean;
    showFinal: boolean;
    showReflection: boolean;
    showResults: boolean;
    possibleCorrectLessons: ILessonPlayModel[];
}
export const getCurrentFacilitationScene = createSelector(selectFacilitation, selectPlay, (state: IFacilitationState, play: IPlayState) => {
    const scene = play.game.scenes[play.game.selectedScene];
    const status = state.classroom.status;
    const isVoteAvailable = !scene.isCutScene && !scene.isSolved && status == EFacilitationStatus.READY;
    const isVoteInProgress = state.classroom.currentSceneId == scene.id && (status == EFacilitationStatus.VOTE1 || status == EFacilitationStatus.VOTE2);
    const showVote1Wrong = status == EFacilitationStatus.RESULT_VOTE1_WRONG;
    const showVote1Correct = status == EFacilitationStatus.RESULT_VOTE1_CORRECT;
    const showDebate = status == EFacilitationStatus.RESULT_DEBATE;
    const showFinal = status == EFacilitationStatus.RESULT_FINAL;
    const showReflection = scene.isSolved && !scene.isCutScene;

    let possibleCorrectLessons = [];
    if (status == EFacilitationStatus.RESULT_DEBATE) {
        possibleCorrectLessons = play.game.lessons.filter(lesson => state.classroom.teams.filter(team => team.learners.filter(learner => learner.firstVoteId == lesson.id).length > 0).length > 0);
    }

    const currentFacilitationScene: ICurrentFacilitationScene = {
        scene,
        status: status,
        isVoteAvailable,
        isVoteInProgress,
        showVote1Wrong,
        showVote1Correct,
        showDebate,
        showFinal,
        showReflection,
        showResults: showVote1Wrong || showVote1Correct || showDebate || showFinal || showReflection,
        possibleCorrectLessons
    }
    return currentFacilitationScene;
});

// Voting Turn
export class ICurrentTurn {
    isVoteActive: boolean;
    isVote1: boolean;
    isVote2: boolean;
    showVote1: boolean;
    lessons: ILessonPlayModel[];
    teams: ITeamPlayModel[];
    currentScene: IScenePlayModel;
    lessonPrompt: string;
    learnerCount: number;
    voteCount: number;
    areAllVotesIn: boolean;
    status: EFacilitationStatus;
}

export const getCurrentTurn = createSelector(selectFacilitation, selectPlay, (state: IFacilitationState, play: IPlayState) => {
    const isVoteActive = state.classroom.status == EFacilitationStatus.VOTE1 || state.classroom.status == EFacilitationStatus.VOTE2;
    const currentScene = play.game.scenes[play.game.selectedScene];
    const lessonPrompt = currentScene.lessonPrompt;
    const learnerCount = state.classroom.teams.reduce((total, team) => { return total + team.learners.length; }, 0);
    const voteCount = state.classroom.teams.reduce((total, team) => { return total + team.learners.filter(learner => learner.firstVoteId != undefined).length; }, 0);

    const currentTurn: ICurrentTurn = {
        isVoteActive: isVoteActive,
        isVote1: state.classroom.status == EFacilitationStatus.VOTE1,
        isVote2: state.classroom.status == EFacilitationStatus.VOTE2,
        showVote1: state.classroom.status == EFacilitationStatus.RESULT_VOTE1_CORRECT || state.classroom.status == EFacilitationStatus.RESULT_VOTE1_WRONG || state.classroom.status == EFacilitationStatus.RESULT_DEBATE || state.classroom.status == EFacilitationStatus.VOTE2 || state.classroom.status == EFacilitationStatus.RESULT_FINAL,
        lessons: play.game.lessons,
        teams: state.classroom.teams,
        currentScene,
        learnerCount,
        lessonPrompt,
        voteCount,
        areAllVotesIn: voteCount == learnerCount,
        status: state.classroom.status
    }

    return currentTurn;
});
