
import { createAction, props } from "@ngrx/store";

import { EGameStatus, IStoryPlayModel, ILessonPlayModel, ERankStatus, ESceneStatus, EBonusStatus, EPlayMode } from "../../models";
import { SoloGameModel } from '../../typings/models/solo-game.model'

/* GAME */

/*
export const loadGame = createAction(
    '[Play] Load Game',
    props<{ storyId: number }>()
)
*/

export const loadDemoGame = createAction(
    '[Play] Load Demo Game',
    props<{ storyId: number, playMode: EPlayMode }>()
)

export const loadGameFromStory = createAction(
    '[Play] Load Game From Story',
    props<{ story: SoloGameModel, playMode: EPlayMode }>()
)

export const loadGameSuccess = createAction(
    '[Play] Load Game Success',
    props<{ game: IStoryPlayModel }>()
)

export const loadGameFailure = createAction(
    '[Play] Load Game Failure',
    props<{ error: Error }>()
)

export const gameLoadRequired = createAction(
    '[Play] Game Load Required'
)

export const gameStartRequired = createAction(
    '[Play] Game Start Required'
)

export const startGame = createAction(
    '[Play] Start Game',
    props<{ historyId: number | undefined }>()
)

export const setStatus = createAction(
    '[Play] Set Status',
    props<{ status: EGameStatus }>()
)


/* MENU */

export const setMenuShowing = createAction(
    '[Play] Set Menu Showing',
    props<{ showing: boolean }>()
)

export const setSidePaneDisabled = createAction(
    '[Play] Set Side Pane Disabled',
)

export const setSidePaneEnabled = createAction(
    '[Play] Set Side Pane Enabled',
)


/* RANK QUESTIONS */

export const setRank1Status = createAction(
    '[Play] Set Rank1 Status',
    props<{ status: ERankStatus }>()
)

export const setRank2Status = createAction(
    '[Play] Set Rank2 Status',
    props<{ status: ERankStatus }>()
)

export const setRankSelection = createAction(
    '[Play] Set Rank Selection',
    props<{ id: number }>()
)


/* SCENE */

export const setSceneViewState = createAction(
    '[Play] Set Scene View State',
    props<{ status: ESceneStatus }>()
)

export const selectScene = createAction(
    '[Play] Select Scene',
    props<{ index: number }>()
)

export const unlockNextScene = createAction(
    '[Play] Unlock Next Scene'
)

export const solveScene = createAction(
    '[Play] Solve Scene'
)

/* SCENE PRE QUESTIONS */

export const answerPreQuestion = createAction(
    '[Play] Answer PreQuestion',
    props<{ answer: string, unlockNext: boolean }>()
)

export const setSceneOptionSelection = createAction(
    '[Play] Set Scene Option Selection',
    props<{ option: string }>()
)


/* SCENE QUESTIONS */

export const setSceneLessonSelection = createAction(
    '[Play] Set Scene Lesson Selection',
    props<{ lesson: ILessonPlayModel }>()
)

export const updateAfterGuess = createAction(
    '[Play] Update After Guess',
    props<{ gameStatus: EGameStatus, sceneStatus: ESceneStatus, correct: boolean, reward: number, lessonId: number }>()
)


/* BONUS QUESTIONS */

export const selectBonus = createAction(
    '[Play] Select Bonus',
    props<{ index: number }>()
)

export const giveBonus = createAction(
    '[Play] Give Bonus',
)

export const setBonusSelection = createAction(
    '[Play] Set Bonus Selection',
    props<{ sceneId: number }>()
)

export const setBonusStatus = createAction(
    '[Play] Set Bonus Status',
    props<{ status: EBonusStatus }>()
)

export const setBonusBet = createAction(
    '[Play] Set Bonus Bet',
    props<{ bet: number }>()
)

export const resolveBonus = createAction(
    '[Play] Resolve Bonus',
    props<{ answer: number, reward: number }>()
)


/* MISC */

export const cheatPlay = createAction(
    '[Play] Cheat Play'
)
