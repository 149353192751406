import { IScenePlayModel } from './scene-play-model';
import { ILessonPlayModel } from './lesson-play-model';
import { IBonusPlayModel } from './bonus-play-model';
import { IAttachmentModel } from './attachment-model';

export enum EGameStatus {
    /*
        PLAY = "PLAY",
        PLAY_COMPLETE = "PLAY_COMPLETE",
        PLAY_RESULTS = "PLAY_RESULTS",
        BONUS = "BONUS",
        BONUS_COMPLETE = "BONUS_COMPLETE",
        BONUS_RANK_LESSONS = "BONUS_RANK_LESSONS",
        BONUS_RESULTS = "BONUS_RESULTS",
        GAME_CLOSE = "GAME_CLOSE",
        GAME_COMPLETE = "GAME_COMPLETE",
        GAME_FAILED = "GAME_FAILED",
    */
    START = "START",
    INTRO = "INTRO",
    SCENE_QUESTION = "SCENE_QUESTION",
    SCENE_RESULT = "SCENE_RESULT",
    BONUS_QUESTION = "BONUS_QUESTION",
    COMPLETE = "COMPLETE",
    FAILED = "FAILED"
}

export enum ERankStatus {
    HIDE = "HIDE",
    QUESTION = "QUESTION",
    RESULT = "RESULT"
}

export enum EPlayMode {
    SOLO = "SOLO",
    FACILITATION = "FACILITATION",
    LEARNER = "LEARNER"
}


export interface IStoryPlayModel {
    id: number;
    title: string;
    imageUrl: string;
    storyTease: string;
    introduction: string;
    lessonPrompt: string;
    storytellerName: string;
    storytellerTitle: string;
    storytellerMediaUrl: string;
    storytellerBio?: string;
    allowableMoves: number;
    scenes: IScenePlayModel[];
    lessons: ILessonPlayModel[];
    bonusQuestions: IBonusPlayModel[];
    attachments: IAttachmentModel[];
    rankLessons: boolean;

    playMode: EPlayMode;
    isDemo: boolean;
    historyId: number;
    points: number;
    bonusPoints: number;
    moves: number;
    bonusDefaultAward: number;
    gameStatus: EGameStatus;
    selectedScene: number;
    selectedBonus: number;
    rank1Status: ERankStatus;
    rank2Status: ERankStatus;
    rankSelection: number;
}
