import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonSlides } from '@ionic/angular';
import { IAttachmentModel } from '../../models/attachment-model';
//import { Observable, of, throwError } from 'rxjs';

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.page.html',
  styleUrls: ['./modal-content.page.scss'],
})
export class ModalContentPage implements OnInit {
  @Input() content: IAttachmentModel[] = undefined;
  @ViewChild('contentSlider', { static: true }) contentSlider: IonSlides;

  slideOpts = {
    initialSlide: 0,
    speed: 200,
    spaceBetween: 3,
    preloadImages: true
  };

  constructor(public modalCtrl: ModalController) { }

  closeModal() {
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }

  prevClicked() {
    this.contentSlider.slidePrev();
  }

  nextClicked() {
    this.contentSlider.slideNext();
  }

  ngOnInit() {
  }

}
