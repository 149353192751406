import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BonusSideComponent } from './bonus-side.component';
import { ChoiceCardComponentModule } from '../choice-card/choice-card.module';
import { ContentScrollerComponentModule } from '../content-scroller/content-scroller.module';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, ChoiceCardComponentModule, ContentScrollerComponentModule],
    declarations: [BonusSideComponent],
    exports: [BonusSideComponent]
})
export class BonusSideComponentModule { }
