import { createSelector, State } from '@ngrx/store';

import { IAppState } from '../state/app.state';
import { IPlayState } from '../state/play.state';
import { ERankStatus, ESceneStatus, EBonusStatus, IScenePlayModel, EGameStatus, EPlayMode } from 'src/app/models';
import { ITrackState } from '../state/track.state';

const selectPlay = (state: IAppState) => state.play;
const selectTrack = (state: IAppState) => state.track;

// game status
export const getGame = createSelector(selectPlay, (state: IPlayState) => state.game);
export const getGameLoaded = createSelector(selectPlay, (state: IPlayState) => state.loaded);
export const getGameError = createSelector(selectPlay, (state: IPlayState) => state.error);
export const getGameStarted = createSelector(selectPlay, (state: IPlayState) => state.gameStarted);
export const getPlayMode = createSelector(selectPlay, (state: IPlayState) => state.game.playMode);

// general game
export const getGameTitle = createSelector(selectPlay, (state: IPlayState) => state.game.title);
export const getMoves = createSelector(selectPlay, (state: IPlayState) => state.game.moves);
export const getPercentMovesLeft = createSelector(selectPlay, (state: IPlayState) => state.game.moves / state.game.allowableMoves * 100);
export const getPoints = createSelector(selectPlay, (state: IPlayState) => state.game.points);
export const getBonusPoints = createSelector(selectPlay, (state: IPlayState) => state.game.bonusPoints);
export const getTotalPoints = createSelector(selectPlay, (state: IPlayState) => { return state.game.points + state.game.bonusPoints });

// Side Menu
export const getIsMenuShowing = createSelector(selectPlay, (state: IPlayState) => state.isMenuShowing);
export const getSidePaneDisabled = createSelector(selectPlay, (state: IPlayState) => state.sidePaneDisabled);
export const getIsContentShift = createSelector(selectPlay, (state: IPlayState) => {
    if (state.sidePaneDisabled) {
        return false;
    } else {
        return state.isMenuShowing;
    }
}
);

// Map Status
export class IMapStatus {
    playMode: EPlayMode;
    sceneCount: number;
    bonusCount: number;
    scenes: IScenePlayModel[];
    selectedSceneIdx: number;
    isIntroCurrent: boolean;
    isSceneCurrent: boolean;
    isBonusCurrent: boolean;
}
export const getMapStatus = createSelector(selectPlay, (state: IPlayState) => {
    return {
        playMode: state.game.playMode,
        sceneCount: state.game.scenes.length,
        bonusCount: state.game.bonusQuestions.length,
        scenes: state.game.scenes,
        selectedSceneIdx: state.game.selectedScene,
        isIntroCurrent: state.game.gameStatus == EGameStatus.INTRO,
        isSceneCurrent: state.game.gameStatus == EGameStatus.SCENE_QUESTION,
        isBonusCurrent: state.game.gameStatus == EGameStatus.BONUS_QUESTION
    }
});

// First Rank Question
export const getFirstRankQuestion = createSelector(selectPlay, selectTrack, (state: IPlayState, track: ITrackState) => {
    const isActive: boolean = state.game.rankLessons;
    const showQuestion: boolean = isActive && state.game.rank1Status === ERankStatus.QUESTION;
    const showResult: boolean = isActive && state.game.rank1Status === ERankStatus.RESULT;
    return {
        isActive,
        showQuestion,
        showResult,
        hideQuestion: !showQuestion && !showResult,
        selectedId: state.game.rankSelection,
        title: "Of the following insights which one holds the most value for you?",
        subtitle: "You’ll be using these throughout the game.",
        answerId: track.rankBeginning
    }
});

// Scene Questions
export const getGameScenes = createSelector(selectPlay, (state: IPlayState) => state.game.scenes.filter(scene => !scene.isBogus));
export const getSelectedSceneIdx = createSelector(selectPlay, (state: IPlayState) => state.game.selectedScene);
export const getSelectedScene = createSelector(selectPlay, (state: IPlayState) => state.game.scenes[state.game.selectedScene]);

export const getSelectedSceneQuestion = createSelector(selectPlay, (state: IPlayState) => {
    const scene = state.game.scenes[state.game.selectedScene];
    const isPreQuestion = scene.preQuestion != undefined;
    const showPreQuestion = isPreQuestion && scene.viewState === ESceneStatus.PREQUESTION;
    const showPreQuestionResult = isPreQuestion && scene.viewState === ESceneStatus.PREQUESTION_RESULT;
    const showQuestion = scene.viewState === ESceneStatus.QUESTION;
    const showResult = scene.viewState === ESceneStatus.QUESTION_RESULT;
    const showCorrect = scene.viewState === ESceneStatus.COMPLETE;
    return {
        isCutScene: scene.isCutScene,
        isPreQuestion,
        showPreQuestion,
        showPreQuestionResult,
        preQuestionOptions: scene.preQuestionOptions,
        preQuestionSelection: scene.preQuestionSelection,
        preQuestionAnswer: scene.preQuestionAnswer,
        preQuestionCorrect: scene.preQuestionCorrect,
        preQuestionResponse: scene.preQuestionResponse,
        isPreQuestionCorrect: scene.preQuestionAnswer == scene.preQuestionCorrect,
        awardedPoints: scene.points,
        showQuestion,
        showResult,
        showCorrect,
        lessonSelection: scene.lessonSelection,
        attempted: scene.attempted,
        title: showPreQuestion || showPreQuestionResult ? scene.preQuestion :
            `${isPreQuestion || state.game.selectedScene <= 1 ? 'Now for' : 'For'} points, ${scene.lessonPrompt.charAt(0).toLowerCase()}${scene.lessonPrompt.slice(1)}`,
        reflection: scene.reflection,
        reflectionAttachments: scene.reflectionAttachments,
        allScenesComplete: state.game.gameStatus == EGameStatus.SCENE_RESULT
    }
});

// Results 
export const getResults = createSelector(selectPlay, (state: IPlayState) => {
    const moves = state.game.moves;
    const points = state.game.points;
    const defaultReward = state.game.bonusDefaultAward;
    const bonusPoints = state.game.bonusPoints;
    const moveBonus = moves * defaultReward;
    return {
        moves,
        points,
        bonusPoints: bonusPoints,
        bonusPointsWithoutMoveBonus: bonusPoints - moveBonus,
        moveBonus,
        totalPoints: points + bonusPoints,
        isBonusRound: state.game.rankLessons || state.game.bonusQuestions.length > 0
    }
});

// Second Rank Question
export const getSecondRankQuestion = createSelector(selectPlay, selectTrack, (state: IPlayState, track: ITrackState) => {
    const isActive: boolean = state.game.rankLessons;
    const showQuestion: boolean = isActive && state.game.rank2Status === ERankStatus.QUESTION;
    const showResult: boolean = isActive && state.game.rank2Status === ERankStatus.RESULT;
    const firstSelectionKeyword = track.rankBeginning == undefined ? "___" : state.game.lessons.find(item => item.id == track.rankBeginning).keyword;
    return {
        isBonusRound: state.game.rankLessons || state.game.bonusQuestions.length > 0,
        isActive,
        showQuestion,
        showResult,
        hideQuestion: !showQuestion && !showResult,
        selectedId: state.game.rankSelection,
        title: `At the beginning, you ranked ${firstSelectionKeyword} as the top lesson. Now that you’ve completed the scenario, has your perspective changed? For you, what is the top lesson?`,
        subtitle: "",
        answerId: track.rankEnd,
        answerChanged: track.rankBeginning != track.rankEnd
    }
});

// Bonus
export const getBonusQuestions = createSelector(selectPlay, (state: IPlayState) => state.game.bonusQuestions);
export const getSelectedBonusIdx = createSelector(selectPlay, (state: IPlayState) => state.game.selectedBonus);
export const getSelectedBonus = createSelector(selectPlay, (state: IPlayState) => state.game.bonusQuestions[state.game.selectedBonus]);

export const getSelectedBonusQuestion = createSelector(selectPlay, (state: IPlayState) => {
    const question = state.game.bonusQuestions[state.game.selectedBonus];
    return {
        showQuestion: question.viewState === EBonusStatus.QUESTION,
        showBet: question.viewState === EBonusStatus.BET,
        showResult: question.viewState === EBonusStatus.COMPLETE,
        //question: question.question,
        question: `${state.game.selectedBonus == 0 ? 'Now for' : 'For'} points, ${question.question.charAt(0).toLowerCase()}${question.question.slice(1)}`,
        choices: state.game.scenes.filter(scene => question.choices.indexOf(scene.id) >= 0),
        selectedId: question.selectedId,
        selectedScene: state.game.scenes.find(scene => scene.id === question.selectedId),
        sceneOrLesson: question.sceneOrLesson,
        maxBet: state.game.bonusPoints,
        bet: question.bet,
        awardedPoints: question.bonusPoints,
        isCorrect: question.guess == question.answerId,
        reasoning: question.reasoning,
        reasoningAttachments: question.reasoningAttachments
    }
});

// Lessons
export const getRemainingLessons = createSelector(selectPlay, (state: IPlayState) => state.game.lessons);
