import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LessonCardComponent } from './lesson-card.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [LessonCardComponent],
    exports: [LessonCardComponent]
})
export class LessonCardComponentModule { }
