import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ContentScrollerComponent } from './content-scroller.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [ContentScrollerComponent],
    exports: [ContentScrollerComponent]
})
export class ContentScrollerComponentModule { }
