import { createSelector } from '@ngrx/store';

import { IAppState } from '../state/app.state';
import { ITrackState } from '../state/track.state';

const selectTrack = (state: IAppState) => state.track;

export const getFirstRank = createSelector(selectTrack, (state: ITrackState) => state.rankBeginning);
export const getEndRank = createSelector(selectTrack, (state: ITrackState) => state.rankEnd);

export const getFullGameStats = createSelector(selectTrack, (state: ITrackState) => state);
