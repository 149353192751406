import { createAction, props } from "@ngrx/store";

import { EFacilitationStatus, IFacilitationPlayModel, ITeamPlayModel, ILearnerModel } from "../../models";

/* GAME */

/*
export const loadDemoFacilitation = createAction(
    '[Facilitation] Load Demo Facilitation'
)
*/

export const loadFacilitationSuccess = createAction(
    '[Facilitation] Load Facilitation Success',
    props<{ facilitation: IFacilitationPlayModel }>()
)

export const loadFacilitationFailure = createAction(
    '[Facilitation] Load Facilitation Failure',
    props<{ error: Error }>()
)

export const facilitationLoadRequired = createAction(
    '[Facilitation] Facilitation Load Required'
)

export const setStatus = createAction(
    '[Facilitation] Set Status',
    props<{ status: EFacilitationStatus }>()
)


/* FACILITATOR */

export const addTeam = createAction(
    '[Facilitation] Add Team',
    props<{ teamName: string }>()
)

export const removeTeam = createAction(
    '[Facilitation] Remove Team',
    props<{ team: ITeamPlayModel }>()
)

export const addLearnerToTeam = createAction(
    '[Facilitation] Add Learner To Team',
    props<{ learner: ILearnerModel, teamId: number }>()
)

export const addTeamPoints = createAction(
    '[Facilitation] Add Team Points',
    props<{ team: ITeamPlayModel, points: number }>()
)

export const startVote = createAction(
    '[Facilitation] Start Vote',
    props<{ sceneId: number, isSecondVote: boolean }>()
)

export const clearVotes = createAction(
    '[Facilitation] Clear Votes'
)

export const updateVote = createAction(
    '[Facilitation] Update Vote',
    props<{ learnerId: number, voteSceneId: number, voteSceneString: string }>()
    //props<{ turnId: number, learnerId: number, voteSceneId: number }>()
)

export const stopVote = createAction(
    '[Facilitation] Stop Vote',
    props<{ status: EFacilitationStatus }>()
)

export const updateTeamScores = createAction(
    '[Facilitation] Update Team Scores',
    props<{ teamPointChange: { teamId: number, points: number }[] }>()
)


/* LEARNER */

export const setTeams = createAction(
    '[Facilitation] Set Teams',
    props<{ teams: ITeamPlayModel[] }>()
)
