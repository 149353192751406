import { Component, OnInit, Input } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { IAppState } from '../../store/state/app.state';
import { getFirstRankQuestion, getSelectedSceneQuestion, getSecondRankQuestion, getSelectedBonus } from '../../store/selectors/play.selector';

@Component({
  selector: 'app-side-header',
  templateUrl: './side-header.component.html',
  styleUrls: ['./side-header.component.scss'],
})
export class SideHeaderComponent implements OnInit {
  @Input() page: string;

  firstRankQuestion = this.store$.pipe(select(getFirstRankQuestion));
  sceneQuestion = this.store$.pipe(select(getSelectedSceneQuestion));
  secondRankQuestion = this.store$.pipe(select(getSecondRankQuestion));
  bonusQuestion = this.store$.pipe(select(getSelectedBonus));

  constructor(private store$: Store<IAppState>) { }

  ngOnInit() { }

}
