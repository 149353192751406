import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SoloScoreComponent } from './solo-score.component';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule],
    declarations: [SoloScoreComponent],
    exports: [SoloScoreComponent]
})
export class SoloScoreComponentModule { }
