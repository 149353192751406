import { ITrackMoveModel, ITrackBonusModel, ITrackExperienceModel } from "../../models";

export interface ITrackState {
    moves: ITrackMoveModel[];
    bonus: ITrackBonusModel[];
    experience: ITrackExperienceModel[];
    rankBeginning: number;
    rankEnd: number;
}

export const initialTrackState: ITrackState = {
    moves: [],
    bonus: [],
    experience: [],
    rankBeginning: undefined,
    rankEnd: undefined
}
