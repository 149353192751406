import { IFacilitationPlayModel, EFacilitationStatus } from "../../models";

export interface IFacilitationState {
    classroom: IFacilitationPlayModel;
    loaded: boolean;
    loading: boolean;
    error: Error;
}

export const initialFacilitationState: IFacilitationState = {
    classroom: {
        id: 0,
        description: "",
        syncCode: "12345",
        status: EFacilitationStatus.NEW,
        teams: [],
        turns: [],
        currentSceneId: -1,
        currentTurnIndex: -1
    },
    loaded: false,
    loading: false,
    error: null
}
