import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LeaderBoardComponent } from './leader-board.component';
import { TeamScoreComponentModule } from '../team-score/team-score.module';

@NgModule({
    imports: [CommonModule, FormsModule, IonicModule, TeamScoreComponentModule],
    declarations: [LeaderBoardComponent],
    exports: [LeaderBoardComponent]
})
export class LeaderBoardComponentModule { }
