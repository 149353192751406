import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SideButtonComponent } from './side-button.component';
import { PageButtonComponentModule } from '../page-button/page-button.module'
import { SoloScoreComponentModule } from '../solo-score/solo-score.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PageButtonComponentModule,
        SoloScoreComponentModule
    ],
    declarations: [SideButtonComponent],
    exports: [SideButtonComponent]
})
export class SideButtonComponentModule { }
