import { Action, createReducer, on } from '@ngrx/store';

import { IFacilitationState, initialFacilitationState } from '../state/facilitation.state';
import { FacilitationActions } from "../actions";
import { EFacilitationStatus, ILearnerModel, ITeamPlayModel } from '../../models';
import { isNgTemplate } from '@angular/compiler';

const reducer = createReducer(
    initialFacilitationState,
    // loadDemoFacilitation
    /*
    on(FacilitationActions.loadDemoFacilitation, (state, { }) => ({
        ...state,
        loaded: true
    })),
    */
    // loadFacilitationSuccess
    on(FacilitationActions.loadFacilitationSuccess, (state, { facilitation }) => ({
        ...state,
        classroom: facilitation,
        loaded: facilitation !== undefined
    })),
    // loadFacilitationFailure
    on(FacilitationActions.loadFacilitationFailure, (state, { error }) => ({
        ...state,
        error: error
    })),
    // setStatus
    on(FacilitationActions.setStatus, (state, { status }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            currentSceneId: status == EFacilitationStatus.READY ? -1 : state.classroom.currentSceneId,
            //currentTurnIndex: status == EFacilitationStatus.READY ? -1 : state.classroom.currentTurnIndex,
            status: status
        }
    })),

    /* FACILITATOR */
    // addTeam
    on(FacilitationActions.addTeam, (state, { teamName }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            teams: [...state.classroom.teams,
            {
                // TODO: dummy in an id for testing, change back to zero
                //id: 0,
                id: state.classroom.teams.length,
                teamName: teamName,
                teamScore: 0,
                learners: []
            }]
        }
    })),
    // removeTeam
    on(FacilitationActions.removeTeam, (state, { team }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            teams: [...state.classroom.teams.filter(item => item != team)]
        }
    })),
    //addLearnerToTeam
    on(FacilitationActions.addLearnerToTeam, (state, { learner, teamId }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            teams: [
                ...state.classroom.teams.map((item) => {
                    if (item.id == teamId) {
                        return {
                            ...item,
                            learners: [...item.learners, learner]
                        }
                    } else {
                        return item;
                    }
                }),
            ]
        }
    })),
    //addTeamPoints
    on(FacilitationActions.addTeamPoints, (state, { team, points }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            teams: [
                ...state.classroom.teams.map((item) => {
                    if (item.id == team.id) {
                        return {
                            ...item,
                            teamScore: (item.teamScore + points) < 0 ? 0 : item.teamScore + points
                        }
                    } else {
                        return item;
                    }
                }),
            ]
        }
    })),
    //startVote
    on(FacilitationActions.startVote, (state, { sceneId, isSecondVote }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            currentSceneId: sceneId,
            /*
            currentTurnIndex: state.classroom.turns.length,
            turns: [...state.classroom.turns, {
                id: 0,
                sceneId: sceneId,
                //sequence: state.classroom.turns.filter(turn => turn.sceneId == sceneId).length + 1,
                sequence: isSecondVote ? 2 : 1,
                votes: []
            }],
            */
            status: isSecondVote ? EFacilitationStatus.VOTE2 : EFacilitationStatus.VOTE1
        }
    })),
    //updateVote
    on(FacilitationActions.updateVote, (state, { learnerId, voteSceneId, voteSceneString }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            teams: [
                ...state.classroom.teams.map((team: ITeamPlayModel) => {
                    return {
                        ...team,
                        learners: [
                            ...team.learners.map((learner: ILearnerModel) => {
                                if (learner.id == learnerId) {
                                    return {
                                        ...learner,
                                        firstVoteId: state.classroom.status == EFacilitationStatus.VOTE1 ? voteSceneId : learner.firstVoteId,
                                        firstVoteString: state.classroom.status == EFacilitationStatus.VOTE1 ? voteSceneString : learner.firstVoteString,
                                        secondVoteId: state.classroom.status == EFacilitationStatus.VOTE2 ? voteSceneId : learner.secondVoteId,
                                        secondVoteString: state.classroom.status == EFacilitationStatus.VOTE2 ? voteSceneString : learner.secondVoteString
                                    }
                                } else {
                                    return learner;
                                }
                            })
                        ]
                    }
                })
            ]
        }
    })),
    // clearVotes
    on(FacilitationActions.clearVotes, (state, { }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            teams: [
                ...state.classroom.teams.map((team: ITeamPlayModel) => {
                    return {
                        ...team,
                        learners: [
                            ...team.learners.map((learner: ILearnerModel) => {
                                return {
                                    ...learner,
                                    firstVoteId: undefined,
                                    firstVoteString: '',
                                    secondVoteId: undefined,
                                    secondVoteString: ''
                                }
                            })
                        ]
                    }
                })
            ]
        }
    })),
    // updateTeamScores
    on(FacilitationActions.updateTeamScores, (state, { teamPointChange }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            teams: [
                ...state.classroom.teams.map((team: ITeamPlayModel) => {
                    const change = teamPointChange.find(change => change.teamId == team.id);
                    const points: number = change ? change.points : 0;
                    return {
                        ...team,
                        teamScore: team.teamScore + points
                    }
                })
            ]
        }
    })),
    //stopVote
    on(FacilitationActions.stopVote, (state, { status }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            status: status
        }
    })),

    /* LEARNER */
    // setTeams
    on(FacilitationActions.setTeams, (state, { teams }) => ({
        ...state,
        classroom: {
            ...state.classroom,
            teams: teams,
            status: EFacilitationStatus.JOIN
        }
    })),
);

export function facilitationReducer(state: IFacilitationState, action: Action) {
    return reducer(state, action);
}

